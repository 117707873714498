import { AdalConfig, AuthenticationContext, withAdalLogin } from "react-adal";
/*
let url = window.location.href.toLowerCase();
let isDev = true;

if (url.indexOf("gph-prod-frontend.azurewebsites.net") >= 0 || url.indexOf("app.greenpowerhub.com") >= 0) {
    isDev = false;
}

const tenant = "montelisn.onmicrosoft.com";
const clientId = isDev ? "f554e709-32a1-48b4-8534-6b1872348f3e" : "2f0437b8-228a-43b8-bd20-c3bb62812b2a";
export const apiId = isDev ? "27285846-f696-4fb3-a6de-8829a417480d" : "3278d85b-eacd-4641-b797-59dfdccc4d98";
export const apiUrl = isDev ? "https://api-isn-dev.azurewebsites.net/api" : "https://gph-prod-api.azurewebsites.net/api";
*/
// Dev
const tenant = "montelisn.onmicrosoft.com";
const clientId = "d4104153-45b4-436c-8981-7c6ed8af6633";
//export const apiId = "27285846-f696-4fb3-a6de-8829a417480d";

// Prod
// const tenant = "montelisn.onmicrosoft.com";
// const clientId = "2f0437b8-228a-43b8-bd20-c3bb62812b2a";
// export const apiId = "3278d85b-eacd-4641-b797-59dfdccc4d98";

const adalConfig: AdalConfig = {
    tenant,
    clientId,
    postLogoutRedirectUri: window.location.origin,
    cacheLocation: "localStorage",
    redirectUri: "http://localhost:3000/"
};

export const authContext = new AuthenticationContext(adalConfig);

export const withAdalLoginApi = withAdalLogin(authContext, "http://localhost:3000/");



// ISN
// import { AdalConfig, AuthenticationContext } from 'react-adal';

// const endpoint = "73493f5e-5db2-425d-9042-0f400cee9c50";
// const appId = "f554e709-32a1-48b4-8534-6b1872348f3e";

// const adalConfig: AdalConfig = {
//     cacheLocation: "localStorage",
//     clientId: appId,
//     endpoints: {
//         api: endpoint
//     },
//     postLogoutRedirectUri: window.location.origin,
//     tenant: "montelisn.onmicrosoft.com"
// };

// export const authContext = new AuthenticationContext(adalConfig);

/*
// FirstPoint
import { AdalConfig, AuthenticationContext } from 'react-adal';

const endpoint = "21e35f02-75f6-4cf6-a2dc-20fecfc9cc9d";
const appId = "1d49e482-3fbf-4abb-a59d-cc827068482d";

const adalConfig: AdalConfig = {
    cacheLocation: "localStorage",
    clientId: appId,
    // endpoints: {
    //     api: endpoint
    // },
    // endpoints: {
    //     api: "ef7ae22d-2bc2-4b88-87ae-d8783c6aae51"
    // },
    postLogoutRedirectUri: window.location.origin,
    tenant: "firstpoint.onmicrosoft.com"
};
*/