import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Spinner } from "../../../Spinner/Spinner";
import { Input } from "@progress/kendo-react-inputs";
import styles from "./PinDialog.module.scss";

type IProps = {
    processingPin: boolean;
    setPinDialogHidden: React.Dispatch<React.SetStateAction<boolean>>;
    pin: React.MutableRefObject<string>;
    validatePin: () => void;
};

export const PinDialog: React.FC<IProps> = ({
    processingPin,
    setPinDialogHidden,
    pin,
    validatePin,
}) => {
    return (
        <Dialog
            title={processingPin ? "Validating..." : "Pin required"}
            onClose={() => {
                setPinDialogHidden(true);
            }}
        >
            <div className={styles.modal}>
                {processingPin ? (
                    <div className={styles.processing}>
                        <div>
                            <Spinner className="" />
                        </div>
                        <div>
                            Please wait while we validate the pin. This will
                            only take a moment.
                        </div>
                    </div>
                ) : (
                    <Input
                        placeholder="Enter pin here"
                        onChange={(ev) => {
                            if (ev.target.value) {
                                pin.current = ev.target.value.toString();
                            }
                        }}
                    />
                )}
            </div>
            <DialogActionsBar>
                <Button
                    primary={true}
                    disabled={processingPin}
                    onClick={validatePin}
                >
                    Validate
                </Button>
                <Button
                    primary={false}
                    disabled={processingPin}
                    onClick={() => {
                        setPinDialogHidden(true);
                    }}
                >
                    Cancel
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
};
