import { SubscriptionCard } from "components/SubscriptionCards/SubscriptionCard/SubscriptionCard";
import React from "react";
import { Limit } from "types/extending";
import { Feature, PaymentInterval, ProductDataType } from "types/membership";
import SwipeElements from "../../../../../components/SwipeElements/SwipeElements";
import { FeatureContent, FeatureDetail } from "../MembershipPlanTable/MembershipPlanTable";
import styles from "./MembershipAllInOne.module.scss";

type IProps = {
    data: ProductDataType[];
    onSubscriptionChange: (productId: string, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    paymentInterval: PaymentInterval;
    productId?: string;
    dealManagerSelected:boolean;
    extraUsers: number;
    marketsSelected:string[];
    limits?: Limit[];
};

export const MembershipAllInOne: React.FC<IProps> = ({
    data,
    onSubscriptionChange,
    paymentInterval,
    productId,
    dealManagerSelected,
    extraUsers,
    marketsSelected,
    limits
}) => {

    const getFeatures = () : Feature[]=>{
        const features: Feature[]= []; 
        for (let i = 0; i < data.length; i++) {
            const p = data[i];
            for (let j = 0; j < p.includedFeatures.length; j++) {
                const f = p.includedFeatures[j].feature;
                if(features.findIndex(feature => feature.id === f.id) < 0  && f.showInSignup){
                    features.push(f);
                }
            }
            for (let j = 0; j < p.extraFeatures.length; j++) {
                const f = p.extraFeatures[j].feature;
                if(features.findIndex(feature => feature.id === f.id) < 0 && f.showInSignup){
                    features.push(f);
                }
            }
        }
        return features;
    }

    const features = getFeatures();

    const getDetails = (product: ProductDataType, feature:Feature)=>{
        let detail: string | boolean = !!product.includedFeatures.find(f => f.feature.id == feature.id);
        if(!detail){
            const extra= product.extraFeatures.find(f => f.feature.id == feature.id);
            detail = extra ? `+ €${extra.price}/mo`: false;
        }
        return detail;
    }

    return (
        <div className={styles.container}>
            <SwipeElements>
                {data
                    .sort((a, b) => a.name > b.name ? 1 : -1)
                    .sort((a, b) => a.price > b.price ? 1 : -1)
                    .map((d, i) => {
                        return(
                            <div className={styles.content}>
                                <div className={styles.plan}>
                                    <SubscriptionCard
                                        key={`cards_${i}`}
                                        product={d}
                                        onSubscriptionChange={onSubscriptionChange}
                                        paymentInterval={paymentInterval}
                                        selected={ productId === d.id}
                                        extraUsers={extraUsers}
                                        dealManagerSelected={dealManagerSelected}
                                        marketsSelected={marketsSelected}
                                        limits={limits}
                                    />
                                </div>
                               
                                 <div className={styles.features}>
                                    {features.map((f, t) => (
                                        <div
                                            className={styles.feature}
                                            key={`card_features_${t}`}
                                        >
                                            <div className={styles.featureDesc}>
                                                <FeatureContent key={f.id} name={f.name} url={f.url}/>
                                            </div>
                                            <div className={styles.featurePlan}>
                                                <FeatureDetail detail={getDetails(d, f)} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    })}
            </SwipeElements>
        </div>
    );
};

            {/* <SwipeElements>
                {data?.length ? (
                    data.map((d, i) => (
                        <div
                            className={styles.content}
                            key={`cardFullWidth_${i}`}
                        >
                            
                            <div className={styles.features}>
                                {features.map((f, t) => (
                                    <div
                                        className={styles.feature}
                                        key={`card_features_${t}`}
                                    >
                                        <div className={styles.featureDesc}>
                                            {f.feature}
                                        </div>
                                        <div className={styles.featurePlan}>
                                            {f.p1}
                                            
                                         
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                ) : (
                    <div style={{ textAlign: "center", color: "var(--error)" }}>
                        Having problems retrieving the products. Please contact
                        us or try again later.
                    </div>
                )}
                </SwipeElements> */}