import { dealManager } from "layouts/layouts";
import { ExtendForm, GetCompanyInfo } from "types/extending";
import { ProductGroup } from "types/membership";
import { buyerSellerViewer, marketRole, title } from "../Steps/MembershipInformation/pageData";
import { SignupForm, ICheckbox, Customer, CustomerAdmin } from "../types/general";
import { BUYERSELLERVIEWER, IMarketRole, IProductGroup} from "../types/membershipInfo";

export const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getErrorMsg = async (err: any): Promise<string> => {
    let msg: any = undefined;
    try {
        msg = await err.text();
    } catch (_) {
        if (typeof msg === "string") {
            return msg;
        }
    }

    if (msg) {
        let msgParsed: any = undefined;
        try {
            msgParsed = JSON.parse(msg);
        } catch (_) {
            if (typeof msg === "string") {
                return msg;
            }
        }
        if (typeof msgParsed === "object" && "title" in msgParsed) {
            return msgParsed.title;
        }
    }

    if (err.status === 401 || err.status === 403) {
        return "Permission denied. Please contact your company administrator.";
    }

    return "An unknown error occured. Please try again soon.";
};

export const joinCheckedArray = (arr: ICheckbox[]) => {
    return arr
        .map((a) => (a.checked ? a.name : undefined))
        .filter((a) => a)
        .join(",");
};

export const preparePost = (v: SignupForm): Customer => {
    const combineCompanyRoles = (
        companyRoles: IMarketRole[],
        otherCompanyRole: string | undefined
    ) => {
        let _companyRoles: ICheckbox[] = [...companyRoles];
        if (otherCompanyRole) {
            _companyRoles = companyRoles.map((cr) =>
                cr.name === "Other"
                    ? {
                          label: cr.label,
                          name: otherCompanyRole,
                          checked: cr.checked,
                      }
                    : cr
            );
        }
        return _companyRoles;
    };

    return {
        claimId: v.claimId,
        contactName: v.contactName,
        email: v.email,
        phoneNumber: v.phoneNumber,
        eikonUsername: v.eikonUsername,

        companyName: v.companyName,
        vatNumber: v.vatNumber,
        orgNo: v.orgNo,
        postalAddress: v.postalAddress,
        postalCode: v.postalCode,
        postalCity: v.postalCity,
        country: v.country,

        companyRole: joinCheckedArray(
            combineCompanyRoles(v.companyRole, v.otherCompanyRole)
        ),

        buyer: !!v.buyerSellerViewer.find(
            (bsv) => bsv.name === BUYERSELLERVIEWER.BUYER
        )?.checked,
        seller: !!v.buyerSellerViewer.find(
            (bsv) => bsv.name === BUYERSELLERVIEWER.SELLER
        )?.checked,
        viewer: !!v.buyerSellerViewer.find(
            (bsv) => bsv.name === BUYERSELLERVIEWER.VIEWER
        )?.checked,

        title: joinCheckedArray(v.title),
        productGroups: joinCheckedArray(v.productGroups),
        price: v.amount!,
        paymentInterval: v.paymentInterval,
        extraUsers: v.extraUsers,
        dealManager: v.dealmanager,

        productId: v.productId!,
        limits: v.limits
    };
};

export const preparePostAdmin = (v: SignupForm): CustomerAdmin => {
    const customer = preparePost(v);
    return{
        ...customer,
        noAlerts : !!v.noAlerts
    }
};

const getProductGroups = (crList: string[], productGroups: ProductGroup[]) => {
    const _marketRole = productGroups.map((mr) => {
        let checkbox: IProductGroup = { 
            name: mr.id,
            label: mr.name,
            checked : !!crList.find((curr: string) => mr.id === curr)
        };
        return checkbox;
    });

    return _marketRole;
};

const getPaymentInterval = (payment: string) => {
    if(payment?.toLocaleLowerCase() === "yearly"){
        return "year";
    }
    return "month";
 };

export const prepareCustomerForForm = (c: Customer, productGroups: ProductGroup[]): SignupForm => {
    const getCompanyRoles = (companyRoles: string) => {
        const crList = companyRoles.split(",");

        const _marketRole = marketRole.map((mr) => {
            let _mr = { ...mr };
            _mr.checked = !!crList.find((curr: string) => _mr.name === curr);
            return _mr;
        });

        return _marketRole;
    };

    const getOtherCompanyRoles = (companyRoles: string) => {
        const crList = companyRoles.split(",");
        let otherRole: string = "";

        crList.map((curr: string) => {
            marketRole.map((mr) => {
                if (mr.name !== curr) otherRole = curr;
            });
        });

        return otherRole;
    };

    const getTitle = (titles: string) => {
        const tList = titles.split(",");
        const _title = title.map((t) => {
            let _t = { ...t };
            _t.checked = !!tList.find((curr: string) => _t.name === curr);
            return _t;
        });

        return _title;
    };

    const getBuyerSellerViewer = (
        buyer: boolean,
        seller: boolean,
        viewer: boolean
    ) => {
        return buyerSellerViewer.map((bsv) => {
            let _bsv = { ...bsv };

            switch (bsv.name) {
                case BUYERSELLERVIEWER.BUYER:
                    _bsv.checked = buyer;
                    break;
                case BUYERSELLERVIEWER.SELLER:
                    _bsv.checked = seller;
                    break;
                case BUYERSELLERVIEWER.VIEWER:
                    _bsv.checked = viewer;
                    break;
            }
            return _bsv;
        });
    };

    return {
        isExistingCustomer: true,
        claimId: c.claimId,
        claimDate: c.claimDate,
        contactName: c.contactName,
        email: c.email,
        phoneNumber: c.phoneNumber,
        eikonUsername: c.eikonUsername,

        companyName: c.companyName,
        vatNumber: c.vatNumber,
        orgNo: c.orgNo,
        postalAddress: c.postalAddress,
        postalCode: c.postalCode,
        postalCity: c.postalCity,
        country: c.country,

        companyRole: getCompanyRoles(c.companyRole),
        otherCompanyRole: getOtherCompanyRoles(c.companyRole),

        title: getTitle(c.title),
        buyerSellerViewer: getBuyerSellerViewer(c.buyer, c.seller, c.viewer),

        paymentInterval: c.paymentInterval,
        productGroups: getProductGroups(c.productGroups.split(","), productGroups),
        amount: c.price,
        extraUsers: c.extraUsers,
        dealmanager: c.dealManager,

        productId: c.productId,

        tocAccepted: false,
        noAlerts: true,
        limits: c.limits
    };
};


export const prepareExtendForm = (c: GetCompanyInfo, productGroups: ProductGroup[]): ExtendForm => {
    return {
        companyName: c.companyName,
        orgNo: c.orgNo,
        paymentInterval: getPaymentInterval(c.paymentInterval),
        productGroups: getProductGroups(c.productGroups, productGroups),
        amount: c.price,
        extraUsers: c.extraUsers,
        dealmanager: !!c.extras?.find(e => e === dealManager.name),
        productId: c.packageId,
        tocAccepted: false,
        userId: c.userId,
        includeTrial: true,
        userEmail : c.userEmail,
        limits: c.limits
    };
};


export const prepareExtendFormForSubmit = (c: ExtendForm): GetCompanyInfo => {
    return {
        companyName: c.companyName,
        orgNo: c.orgNo,
        userId: c.userId,
        packageId: c.productId,
        extraUsers: c.extraUsers,
        extras: c.dealmanager? [dealManager.name]: [],
        paymentInterval: c.paymentInterval,
        productGroups: joinCheckedArray(c.productGroups).split(","),
        userEmail: c.userEmail,
        includeTrial: true,
        price: c.amount,
        currency: "EUR",
        limits: c.limits
    };
};

export const getPrice = (price:number, markets:number, discount: number, dealManager: number, extraUsers: number, extraUserPrice: number, extraMarketPrice : number): number =>{
    let basePrice = price  + dealManager + extraUsers* extraUserPrice;
    if(markets > 1){
        basePrice += extraMarketPrice* (markets-1) ;
    }
    return basePrice * (100-discount)/100;
}