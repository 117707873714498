import { Icon } from "@progress/kendo-react-common";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import AppStore from "../../stores/AppStore";
import styles from "./Lookup.module.scss";
import { SearchCompanies } from "./components/SearchCompanies/SearchCompanies";
import { TopMenu } from "../TopMenu/TopMenu";
import { PinDialog } from "./components/PinDialog/PinDialog";
import { Customer } from "../../types/general";

export const Lookup = () => {
    const store = useContext(AppStore);
    const [searchString, setSearchString] = useState<string>("");
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [filters, setFilters] = useState<any[]>([]);
    const [selectedFilters, setSelectedFilters] = useState<
        { key: string; text: string }[]
    >([]);
    const [pinDialogHidden, setPinDialogHidden] = useState<boolean>(true);
    const [processingPin, setProcessingPin] = useState<boolean>(false);
    const history = useHistory();
    const pin = useRef<string>("");
    const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    };

    useEffect(() => {
        let _filters: any[] = [
            // { key: "includeClaimed", text: "Include claimed" }
        ];

        store.api.getCategories().then((_) => {
            _.forEach((c: string, index: number) => {
                _filters.push({ key: `cat_${index}`, text: c });
            });

            setFilters(_filters);
            search();
        });
    }, []);

    useEffect(() => {
        search();
    }, [selectedFilters]);

    const search = () => {
        if (!store.apiAdminKey) {
            setPinDialogHidden(false);
            return;
        }

        // if (searchString && searchString.length > 0) {
        let selectedCategories = filters
            .filter((_) => {
                return selectedFilters.length && _.key !== "includeClaimed";
            })
            .map((_) => {
                return _.text;
            });

        store.api
            .getCustomers(
                store.apiAdminKey,
                searchString,
                // !!selectedFilters.find((f) => f.key === "includeClaimed"),
                selectedCategories
            )
            .then((_) => {
                setCustomers(_);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const validatePin = () => {
        setProcessingPin(true);

        store.api
            .getAdminApiKey(pin.current)
            .then((_) => {
                setProcessingPin(false);
                setPinDialogHidden(true);
                store.apiAdminKey = _.apiKey;
                search();
                setTimeout(() => {
                    search();
                }, 500);
            })
            .catch((err) => {
                console.log(err);
                setProcessingPin(false);
            });
    };

    return (
        <div>
            <TopMenu />
            <div className={styles.container}>
                <div className={styles.header}>
                    <div>
                        <SearchCompanies
                            setSearchString={setSearchString}
                            search={search}
                        />
                        <div className={styles.selectedFilters}>
                            <div>
                                <Icon name="filter" />
                            </div>
                            {selectedFilters.length > 0 &&
                                selectedFilters.map((c, index) => {
                                    return (
                                        <div key={`selected_${index}`}>
                                            {c.text}
                                        </div>
                                    );
                                })}
                            {selectedFilters.length === 0 && (
                                <div>No filters selected</div>
                            )}
                        </div>
                    </div>
                    <div>
                        <MultiSelect
                            data={filters}
                            onChange={(ev) => {
                                setSelectedFilters(ev.target.value);
                            }}
                            tagRender={() => <div></div>}
                            filter="Filters"
                            textField="text"
                            dataItemKey="key"
                            autoClose={false}
                            placeholder="Filters"
                            className={styles.filtersDropdown}
                        />
                    </div>
                </div>
                <div className={styles.results}>
                    {customers.map((c) => {
                        return (
                            <div
                                key={c.claimId}
                                onClick={() => {
                                    history.push(`/signup?id=${c.claimId}`);
                                }}
                            >
                                <div className={styles.resultHeader}>
                                    <div>{c.companyName}</div>
                                    {c.claimDate && (
                                        <div className={styles.claimed}>
                                            Claimed
                                        </div>
                                    )}
                                </div>
                                <div className={styles.details}>
                                    <div>
                                        <span>Contact:</span> {c.contactName}
                                    </div>
                                </div>
                                {c.claimDate && (
                                    <div className={styles.right}>
                                        <div>
                                            <span>Claim date:</span>{" "}
                                            {new Date(
                                                c.claimDate
                                            ).toLocaleDateString(
                                                "en-US",
                                                options
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>

                {!pinDialogHidden && (
                    <PinDialog
                        processingPin={processingPin}
                        setPinDialogHidden={setPinDialogHidden}
                        pin={pin}
                        validatePin={validatePin}
                    />
                )}
            </div>
        </div>
    );
};
