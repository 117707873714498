import { useContext, useEffect, useState } from "react";
import AppStore from "../stores/AppStore";

export const useCountryData = () => {
    const store = useContext(AppStore);
    const [countries, setCountries] = useState<any>([]);

    useEffect(() => {
        store.api.getCountries().then((countries) => {
            setCountries(
                countries.map((country: any) => {
                    return country.name;
                })
            );
        });
    }, []);

    return countries;
};
