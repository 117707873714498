import React from "react";
import { Field } from "@progress/kendo-react-form";
import { FormInput } from "../../components/FormInput";
import {
    emailValidator,
    lastNameValidator,
    phoneValidator,
} from "../../validators";
import styles from "./UserInformation.module.scss";

export const UserInformation: React.FC = () => {
    const phoneNumber = "+47 413 45 660";
    return (
        <div className={styles.container}>
            <h1>Amazing!</h1>
            <section>
                <p>
                    <b>14-days free trial</b> is just a few steps away.
                </p>
            </section>
            <section>
                <p> We are here to help you. </p>
                <p>
                    {" "}
                    Do not hesitate to contact us, use our chat or call us at{" "}
                    <a href={"tel:" + phoneNumber}>{phoneNumber}</a>
                </p>
            </section>

            <h2>Create an account</h2>
            <div>
                <Field
                    key={"contactName"}
                    id={"contactName"}
                    name={"contactName"}
                    label={"Full name"}
                    component={FormInput}
                    validator={lastNameValidator}
                />
                <Field
                    key={"email"}
                    id={"email"}
                    name={"email"}
                    label={"Email"}
                    type={"email"}
                    component={FormInput}
                    validator={emailValidator}
                />
                <Field
                    key={"phoneNumber"}
                    id={"phoneNumber"}
                    name={"phoneNumber"}
                    label={"Phone Number"}
                    component={FormInput}
                    validator={phoneValidator}
                />
                <Field
                    key={"eikonUsername"}
                    id={"eikonUsername"}
                    name={"eikonUsername"}
                    label={"Eikon username (optional)"}
                    component={FormInput}
                />
            </div>
        </div>
    );
};
