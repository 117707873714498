import { ProductDataType } from "types/membership";
import { ICheckbox } from "./types/general";
import { emailBlacklist } from "./utils/emailBlacklist";
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);

export const termsValidator = (value: string) =>
    value ? "" : "It's required to agree with Terms and Conditions.";

export const onlyLettersAndSpaces = (value: string) => {
    return value
        ? /^[a-z][a-z\s]*$/.test(value)
            ? ""
            : "Must only contain lettes and spacing."
        : "Please fill in a company market role";
};

const publicEmailValidator = (value: string): string => {
    const emailBlackListRegex = new RegExp(
        `[a-zA-Z0-9]{0,}([.]?[a-zA-Z0-9]{1,})[@](${emailBlacklist.join("|")})`
    );

    return emailBlackListRegex.test(value)
        ? "Must be a company email address"
        : "";
};

export const emailValidator = (value: string) => {
    return !value
        ? "Email field is required."
        : emailRegex.test(value)
        ? publicEmailValidator(value)
        : "Email is not valid format.";
};

export const firstNameValidator = (value: string) =>
    !value
        ? "First Name is required"
        : value.length < 2
        ? "First Name should be at least 2 characters long."
        : "";

export const lastNameValidator = (value: string) =>
    !value
        ? "Last Name is required"
        : value.length < 2
        ? "Last Name should be at least 2 characters long."
        : "";

export const companyNameValidator = (value: string) =>
    !value
        ? "Company Name is required"
        : value.length < 2
        ? "Please fill in Company Name"
        : "";

export const vatValidator = (value: string) =>
    !value
        ? "VAT / Organization number is required"
        : value.length < 2
        ? "Please fill in your companies VAT / Organization number."
        : "";

export const requiredValidator = (value: string) =>
    value ? "" : "Error: This field is required.";

export const addressValidator = (value: string) =>
    value ? "" : "Address is required.";

export const phoneValidator = (value: string) =>
    !value
        ? "Phone number is required."
        : phoneRegex.test(value)
        ? ""
        : "Not a valid phone number.";

export const otherCompanyRoleValidator = (value: string) =>
    requiredValidator(value);

export const requiredCheckboxValidator = (value: any) => {
    const hasChecked = value.filter((item: ICheckbox) => item.checked);
    if (hasChecked.length > 0) return "";
    else return "Error: This field is required.";
};


export const productValidator = (products: ProductDataType[], value: string) =>
    !value || ! products.find(product => product.id ==value) 
        ? "Please select a package"
        : "";