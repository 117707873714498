import React from "react";
import { Limit } from "types/extending";
import { ProductDataType, PaymentInterval} from "../../types/membership";
import { SubscriptionCard } from "./SubscriptionCard/SubscriptionCard";
import styles from "./SubscriptionCards.module.scss";

type IProps = {
    data: ProductDataType[];
    onSubscriptionChange: (productId: string, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    paymentInterval: PaymentInterval;
    productId?: string;
    dealManagerSelected:boolean;
    extraUsers: number;
    marketsSelected:string[];
    limits?: Limit[];
};

export const SubscriptionCards: React.FC<IProps> = ({
    data,
    onSubscriptionChange,
    paymentInterval,
    productId,
    dealManagerSelected,
    extraUsers,
    marketsSelected,
    limits
}) => {
    return (
        <div className={styles.container}>
            {data
            .sort((a, b) => a.name > b.name ? 1 : -1)
            .sort((a, b) => a.price > b.price ? 1 : -1)
            .map((d, i) => {
                return(
                    <SubscriptionCard
                        key={`cards_${i}`}
                        product={d}
                        onSubscriptionChange={onSubscriptionChange}
                        paymentInterval={paymentInterval}
                        selected={ productId === d.id}
                        extraUsers={extraUsers}
                        dealManagerSelected={dealManagerSelected}
                        marketsSelected={marketsSelected}
                        limits={limits}
                    />
                )
            })}
        </div>
    );
};
