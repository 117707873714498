import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import React from "react";
import styles from "./AddressCard.module.scss";

type IProp = {
    company: {
        companyName: string;
        adress: string;
        postalCode: string;
        city: string;
        country: string;
    };
};

export const AddressCard: React.FC<IProp> = ({
    company: { companyName, adress, postalCode, city, country },
}) => {
    return (
        <div
            style={{
                marginBottom: "24px",
            }}
        >
            <ul
                style={{
                    listStyle: "none",

                    padding: 0,
                }}
            >
                <li>
                    <b>{companyName}</b>
                </li>
                <li>{adress}</li>
                <li>
                    {postalCode}{" "}
                    <span className={styles.capitalize}>{city}</span>
                </li>

                <li>
                    <span className={styles.uppercase}>{country}</span>
                </li>
            </ul>
        </div>
    );
};
