import { ICheckbox } from "./general";

export type BuyerSellerViewerType = "Buyer" | "Seller" | "Viewer";
export type TitleType = "Trader" | "Backoffice" | "RiskManager" | "Manager";
export type MarketRoleType =
    | "Producer"
    | "Consumer"
    | "Broker"
    | "Consulting"
    | "Analytics"
    | "Other";

export const BUYERSELLERVIEWER: {
    BUYER: BuyerSellerViewerType;
    SELLER: BuyerSellerViewerType;
    VIEWER: BuyerSellerViewerType;
} = {
    BUYER: "Buyer",
    SELLER: "Seller",
    VIEWER: "Viewer",
};

export const TITLE: {
    TRADER: TitleType;
    BACKOFFICE: TitleType;
    RISKMANAGER: TitleType;
    MANAGER: TitleType;
} = {
    TRADER: "Trader",
    BACKOFFICE: "Backoffice",
    RISKMANAGER: "RiskManager",
    MANAGER: "Manager",
};

export const MARKETROLE: {
    PRODUCER: MarketRoleType;
    CONSUMER: MarketRoleType;
    BROKER: MarketRoleType;
    CONSULTING: MarketRoleType;
    ANALYTICS: MarketRoleType;
    OTHER: MarketRoleType;
} = {
    PRODUCER: "Producer",
    CONSUMER: "Consumer",
    BROKER: "Broker",
    CONSULTING: "Consulting",
    ANALYTICS: "Analytics",
    OTHER: "Other",
};

export interface IBuyerSellerViewer extends ICheckbox {
    name: BuyerSellerViewerType;
}

export interface ITitle extends ICheckbox {
    name: TitleType;
}

export interface IMarketRole extends ICheckbox {
    name: MarketRoleType;
}

export interface IProductGroup extends ICheckbox {
    name: string;
}
