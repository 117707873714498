import styles from "./BottomNav.module.scss";
import arrowLeftIcon from "../../icons/arrow-left.svg";
import arrowRightIcon from "../../icons/arrow-right.svg";
import React from "react";
import Button from "../Button/Button";
import { Step } from "components/SignupContainer/signupContainerHelper";

type IProp = {
    handlePrevious: () => void;
    onSubmit: (event: React.SyntheticEvent<any, Event>) => void;
    step: Step;
};

export const BottomNav: React.FC<IProp> = ({
    handlePrevious,
    onSubmit,
    step
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.navWrapper}>
                {!step.isFirstStep &&(
                    <Button
                        className={styles.prevButton}
                        look="flat"
                        icon={arrowLeftIcon}
                        onClick={handlePrevious}
                    >
                        Previous
                    </Button>
                )}
                {/* {!step.isSelectMembershipPage && ( */}
                    <Button
                        className={styles.nextButton}
                        primary
                        icon={arrowRightIcon}
                        iconPosition="right"
                        onClick={onSubmit}
                        type="submit"
                    >
                        {step.isSubmitStep ? "Finish" : "Next"}
                    </Button>
                {/* )} */}
            </div>
        </div>
    );
};
