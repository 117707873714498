import styles from "./IntroSidebar.module.scss";
import gphLogo from "../../img/gph-logo-signup.svg";
import React from "react";

export const IntroSidebar = () => {
    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.logo}>
                    <img src={gphLogo} />
                </div>
                <div className={styles.slogan}>
                    Meet and trade with your trusted counterparties in a
                    membership-based network for Energy Attribute Certificate (EACs) and
                    Elcerts, designed for simplicity and efficiency.
                </div>
            </div>
        </div>
    );
};
