import { Field } from "@progress/kendo-react-form";
import React from "react";
import { PaymentInterval } from "types/membership";
import { FormRadioGroup } from "../../../../components/FormInput";

type IProps = {
    paymentIntervals: PaymentInterval[];
    layout: string;
};

export const PaymentIntervals: React.FC<IProps> = ({
    paymentIntervals,
    layout,
}) => {
    return (
        <Field
            key={"paymentInterval"}
            id={"paymentInterval"}
            name={"paymentInterval"}
            label={"Payment interval"}
            layout={layout}
            component={FormRadioGroup}
            data={paymentIntervals}
            defaultValue={"month"}
        />
    );
};
