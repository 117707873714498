import { Field } from "@progress/kendo-react-form";
import React from "react";
import { FormNumericTextBox } from "../../../../components/FormInput";

export const AddExtraUsers = () => {
    return (
        <Field
            key={"extraUsers"}
            id={"extraUsers"}
            name={"extraUsers"}
            label={"Extra Users"}
            format={"n0"}
            component={FormNumericTextBox}
            min={0}
            max={20}
            step={1}
            defaultValue={0}
            width={100}
        />
    );
};
