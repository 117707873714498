import { Field } from "@progress/kendo-react-form";
import React from "react";
import { FormComboBox } from "./FormInput";
import { requiredValidator } from "../validators";
import { useCountryData } from "../hooks/useCountryData";
interface Props {}

export const CountryDropdown: React.FC<Props> = () => {
    const countries = useCountryData();
    return (
        <Field
            key={"country"}
            id={"country"}
            name={"country"}
            label={"Country"}
            component={FormComboBox}
            data={countries}
            validator={requiredValidator}
            loading={countries.length === 0}
            placeholder="e.g. Sweden"
        />
    );
};
