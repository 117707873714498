export class AppUtils {

    public static getUrlParam(key: string, urlParams?: string): string | null {
        if (!urlParams) {
            return null;
        }

        var allParams = new URLSearchParams(urlParams.toLowerCase());
        return allParams.has(key) ? allParams.get(key) : null;
    }
}