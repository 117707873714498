import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { FormCheckboxGroup, FormInput } from "components/FormInput";
import React from "react";
import { event } from "react-ga";
import { IProductGroup } from "types/membershipInfo";
import { getPrice } from "utils/utils";
import { productValidator, requiredCheckboxValidator } from "validators";
import { SubscriptionCards } from "../../components/SubscriptionCards/SubscriptionCards";
import { dealManager, paymentIntervals} from "../../layouts/layouts";
import { ProductDataType } from "../../types/membership";
import { AddExtraUsers } from "./components/AddExtraUsers/AddExtraUsers";
import { DealManager } from "./components/DealManager/DealManager";
import { MembershipAllInOne } from "./components/MembershipPlan/MembershipAllInOne/MembershipAllInOne";
import { MembershipPlan } from "./components/MembershipPlan/MembershipPlan";
import { PaymentIntervals } from "./components/PaymentIntervals/PaymentIntervals";
import styles from "./SelectMembership.module.scss";

interface Props {
    mobile: boolean | undefined;
    formRenderProps: FormRenderProps;
    products: ProductDataType[];
}

export const SelectMembership: React.FC<Props> = ({
    formRenderProps,
    mobile,
    products,
}) => {


    const getPaymentInterval = ()=>{
        const p = formRenderProps.valueGetter("paymentInterval");
        const selected = paymentIntervals.find(interval => interval.name == p)?? paymentIntervals[0];
        return selected;
    }

    const getSelectedMarkets = ()=>{
        const selectedMarkets = formRenderProps.valueGetter("productGroups").filter((selected:IProductGroup) => selected.checked);
        return selectedMarkets;
    }

    const getDealManager = ()=>{
        const isSelected = formRenderProps.valueGetter(dealManager.name);
        return isSelected;
    }

    const getExtraUsers = ()=>{
        const extraUsers = formRenderProps.valueGetter("extraUsers");
        return extraUsers;
    }

    const getPackageLimits = ()=>{
        const limits = formRenderProps.valueGetter("limits");
        return limits;
    }


    const getPackage = ()=>{
        const p = formRenderProps.valueGetter("productId");
        const selected = products.find(product => product.id == p);
        return selected;
    }

    const updatePrice = ()=>{
        const pi = getPaymentInterval();
        const pack = getPackage() ?? products[0];
        const markets = getSelectedMarkets();
        const extraUsers = getExtraUsers();
        const dm = getDealManager();
        
        const packdm = pack.extraFeatures.find(feature => feature.feature.id == dealManager.name);

        const dmPrice = dm && packdm? packdm.price : 0;
        const price = getPrice(pack.price, markets.length, pi.discount, dmPrice, extraUsers, pack.priceExtraUser?? 0, pack.extraMarketPrice)
        formRenderProps.onChange("amount", { value: price });
    }

    const subscriptionChange = (productId: string, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const markets = getSelectedMarkets();

        event({
            category: "Subscription",
            action: "Click",
            label: productId,
        });
        if (markets.length > 0) {
            formRenderProps.onChange("productId", { value: productId });
            formRenderProps.onChange("limits", {value: null});
            updatePrice();
            // formRenderProps.onSubmit(e)
        } else {
            event({
                category: "Subscription",
                action: "Click with no market",
                label: productId,
            });
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.contentSpacing}>
                <div className={styles.heading}>
                    <h2>Select your membership</h2>
                    <p>
                        All new membership start with 14-days free trial, Enjoy!
                    </p>
                </div>
                <div className={styles.controllers}>
                    <PaymentIntervals
                        paymentIntervals={paymentIntervals}
                        layout={"horizontal"}
                    />
                    <Field
                        key={"productGroups"}
                        id={"productGroups"}
                        name={"productGroups"}
                        label={"Markets"}
                        layout={"horizontal"}
                        component={FormCheckboxGroup}
                        data={formRenderProps.valueGetter("productGroups")}
                        validator={requiredCheckboxValidator}
                    />
                    <AddExtraUsers />
                    <DealManager isChecked={getDealManager()}/>
                </div>
            </div>
            <Field
                key={"productId"}
                id={"productId"}
                name={"productId"}
                layout={"horizontal"}
                validator={(value: string)=> productValidator(products, value)} 
                component={FormInput}
                style={{display: "none"}}       
            />
            {mobile ?
                <div className={styles.tableSpacing}>
                    <MembershipAllInOne
                        data={products}
                        onSubscriptionChange={subscriptionChange}
                        paymentInterval={getPaymentInterval()}
                        productId={getPackage()?.id}
                        dealManagerSelected={getDealManager()}
                        extraUsers={getExtraUsers()}
                        limits = {getPackageLimits()}
                        marketsSelected={getSelectedMarkets().map((market: IProductGroup) => market.label)}
                    />
                </div>
            : (
                <div className={styles.tableSpacing}>
                    <div className={styles.cards}>
                        <SubscriptionCards
                            data={products}
                            onSubscriptionChange={subscriptionChange}
                            paymentInterval={getPaymentInterval()}
                            productId={getPackage()?.id}
                            dealManagerSelected={getDealManager()}
                            limits = {getPackageLimits()}
                            extraUsers={getExtraUsers()}
                            marketsSelected={getSelectedMarkets().map((market: IProductGroup) => market.label)}
                        />
                    </div>
                    {products.length &&
                        <MembershipPlan 
                            products={products}
                            paymentInterval={getPaymentInterval()}
                        />
                    }
                </div>
            )}
            
        </div>
    );
};
