import { createContext } from "react";
import { Api } from "../api/Api";
import {AppSettings} from "../appSettings"

export class AppStore {
    api: Api;
    apiAdminKey: string;
    isDev: boolean;

    constructor(url: string) {
        this.api = new Api(url);
    }
}
export default createContext(new AppStore(AppSettings.apiUrl));
