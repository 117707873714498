import { Card, CardBody, CardTitle } from "@progress/kendo-react-layout";
import React from "react";
import paymentIcon from "../../../../icons/card.svg";
import marketIcon from "../../../../icons/market.svg";
import usersIcon from "../../../../icons/users.svg";
import dealManagerIcon from "../../../../icons/deal-manager.svg";
import {
    capitalizeFirstLetter,
} from "../../../../utils/utils";
import styles from "./SummaryCard.module.scss";
import { dealManager, paymentIntervals } from "layouts/layouts";
import { ITermsAndPayment } from "Steps/TermsAndPayment/TermsAndPayment";
import { IProductGroup } from "types/membershipInfo";
import { useLocation } from "react-router-dom";

export const SummaryCard: React.FC<ITermsAndPayment> = ({
    formRenderProps,
    productGroups,
    products
}) => {

    const location = useLocation();
    const  includeSignupFee = !location.pathname.includes("extend");

    const getProduct = ()=>{
        const p = formRenderProps.valueGetter("productId");
        const selected = products.find(product => product.id == p)?? products[0];
        return selected;
    }
    const getPaymentInterval = ()=>{
        const p = formRenderProps.valueGetter("paymentInterval");
        const selected = paymentIntervals.find(interval => interval.name == p)?? paymentIntervals[0];
        return selected;
    }
    const getSelectedMarkets = ()=>{
        const selectedMarkets = formRenderProps.valueGetter("productGroups").filter((selected:IProductGroup) => selected.checked)
        .map((pg:IProductGroup) => pg.label);
        return selectedMarkets.join(", ");
    }

    const amount =formRenderProps.valueGetter("amount");
    const extraUsers= formRenderProps.valueGetter("extraUsers");
    const dm= formRenderProps.valueGetter(dealManager.name);

    const cardColor= getProduct().id == "viewer"? "white" : "dark";


    return (
        <Card
            className={`${styles.container} ${styles[cardColor]}`}
        >
            <CardBody className={styles.body}>
                <CardTitle>
                    <div>
                        <h3>{getProduct().name}</h3>
                        <div
                            className={styles.amount}
                        >{`€ ${amount}/mo`}</div>
                    </div>
                </CardTitle>
                <div className={styles.markets}>
                    <img src={marketIcon} />
                    <p>{getSelectedMarkets()}</p>
                </div>
                <div className={styles.users}>
                    <img src={usersIcon} />
                    <p>{getProduct().users + extraUsers}</p>
                </div>
                {dm && (
                    <div className={styles.users}>
                        <img src={dealManagerIcon} />
                        <div>{dealManager.label}</div>
                    </div>
                )}
                <div className={styles.paymentInterval}>
                    <img src={paymentIcon} />
                    <p> {capitalizeFirstLetter(getPaymentInterval().label)}{" "}payment
                    </p>
                </div>
                {includeSignupFee &&
                    <div className={styles.paymentInterval}>
                        <p> {`€ ${getProduct().signupFee}`} signup fee
                        </p>
                    </div>
                }
               
            </CardBody>
        </Card>
    );
};
