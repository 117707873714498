import { Field } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import React from "react";
import { FormCheckbox } from "../FormInput";
import styles from "./CheckboxGroup.module.scss";

type IProps = {
    data: {
        name: string;
        label: string;
        checked?: boolean;
    }[];
    layout: string;
    label: string;
    error?: string;
};

export const CheckboxGroup: React.FC<IProps> = ({
    data,
    layout,
    label,
    error
}) => {
    return (
        <div className={styles.container}>
            <Label className="block">{label}</Label>
            <div className={styles.flex}>
                {data.map(({ name, label, checked = false }) => (
                    <Field
                        key={name}
                        id={name}
                        name={name}
                        label={label}
                        component={FormCheckbox}
                        defaultChecked={checked}
                        layout={layout}
                    />
                ))}
            </div>
            <div className={styles.error}>{error ? error : ""}</div>
        </div>
    );
};
