import React from "react";
import { SvgIcon } from "@progress/kendo-react-common";
import { checkCircleIcon } from "@progress/kendo-svg-icons";
import styles from "./finished.module.scss";

declare global {
    interface Window {
        HubSpotConversations: any;
    }
}

window.HubSpotConversations = window.HubSpotConversations || {};

export const FinishedExtend: React.FC = () => (
    <div className={styles.container}>
        <div className={styles.header}>
            <SvgIcon
                icon={checkCircleIcon}
                size="large"
                themeColor={"primary"}
            />
            <h1>Thank you!</h1>
        </div>

        <p className={styles.highlight}>
            Thank you for extending your subscription at the Green Power Hub.
        </p>
        <p>You may resume activity on the platform.</p>
        <a
            style={{
                fontWeight: "bold",
                textDecoration: "none",
                borderBottom: "2px solid green",
            }}
            href={getGphAppUrl()}
        >
            Open Green Power Hub
        </a>
        <div className={styles.tySection}>
            <p>All the best.</p>
            <p>- The Green Power Hub Team</p>
        </div>
    </div>
);
const getGphAppUrl = () => {
    const url = window.location.href.toLowerCase();
    if (url.includes("localhost")) return "http://localhost:3000";
    if (url.includes("gphsignup-dev")) return "https://dev.greenpowerhub.com/";

    return "https://app.greenpowerhub.com/";
};
