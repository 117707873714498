import React from "react";
import styles from "./MembershipPlanTable.module.scss";
import checkIcon from "icons/accept.svg";
import { Feature, PaymentInterval, ProductDataType } from "types/membership";


type IMembershipPlanTable = {
    products: ProductDataType[];
    paymentInterval: PaymentInterval;
};

export const MembershipPlanTable: React.FC<IMembershipPlanTable> = ({
    products,
    paymentInterval
}) => {
    const getFeatures = () : Feature[]=>{
        const features: Feature[]= []; 
        for (let i = 0; i < products.length; i++) {
            const p = products[i];
            for (let j = 0; j < p.includedFeatures.length; j++) {
                const f = p.includedFeatures[j].feature;
                if(features.findIndex(feature => feature.id === f.id) < 0  && f.showInSignup){
                    features.push(f);
                }
            }
            for (let j = 0; j < p.extraFeatures.length; j++) {
                const f = p.extraFeatures[j].feature;
                if(features.findIndex(feature => feature.id === f.id) < 0 && f.showInSignup){
                    features.push(f);
                }
            }
        }
        return features;
    }

    const features = getFeatures();

    return (
        <div className={styles.container}>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        {products.map((h, i) => {
                            const cardColor= h.id == "viewer"? "white" : "dark";
                            return (
                                <th
                                    key={`membershipplantable_${i}`}
                                    className={`${styles.columnHeader} ${styles[cardColor]}`}
                                >
                                    {h.name}
                                </th>
                            )}
                        )}
                    </tr>
                </thead>
                <tbody>
                    {features.map((r, i) => (
                        <tr key={`mpt_row_${i}`}>
                            <td><FeatureContent key={r.id} name={r.name} url={r.url}/></td>
                            {products.map((h, i) => {
                                let detail: string | boolean = !!h.includedFeatures.find(f => f.feature.id == r.id);
                                if(!detail){
                                    const extra= h.extraFeatures.find(f => f.feature.id == r.id);
                                    const price = extra ? extra.price * (100-paymentInterval.discount)/100 : 0;
                                    detail = price ? `+ €${price}/mo`: false;
                                }
                                return(<td key={r.id + h.id}><FeatureDetail detail={detail} /></td>)
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export const FeatureContent: React.FC<{ name: string, url?: string }> = ({ name, url }) => {
    if (!url) {
        return <div className={styles.featureContent}><span >{name}</span></div>
    }
    return (<div className={styles.featureContent}>

        <span>{name}</span>
        <span style={{ marginLeft: 15 }}><a href={url} target="blank" title="Opens in new tab">More info</a></span>
    </div>
    )
}

export const FeatureDetail: React.FC<{ detail?: string | boolean }> = ({ detail }) => {

    if (!detail) return <></>

    if (typeof detail === "string")
        return (<span>{detail}</span>)

    if (detail === true) {
        return (<img src={checkIcon} alt="checked" />)
    }
    return <></>
}
