import { GetCompanyInfo } from "./../types/extending";
import { Customer } from "../types/general";

export class Api {
    private baseUrl;
    // private baseUrl = "https://localhost:44324/api";

    constructor(url: string) {
        this.baseUrl = url;
    }

    private goFetch(
        url: string,
        params?: string,
        method?: string,
        body?: any,
        additionalHeaders?: any
    ): Promise<any> {
        let headers = {
            "Content-Type": "application/json",
            Authorization: `Basic ${btoa("isnSignUp:h7eFwswe4loU%dh")}`,
            ...additionalHeaders,
        };

        let completeUrl = `${this.baseUrl}${url}${params ? `?${params}` : ""}`;

        let options: RequestInit = {
            method: method ? method : "GET",
            body: body ? JSON.stringify(body) : undefined,
            headers: headers,
        };

        return fetch(completeUrl, options)
            .then((_: any) => {
                return _.ok ? _ : Promise.reject(_);
            })
            .then((_) => {
                return _;
            });
    }

    public getCustomer(id: string): Promise<Customer> {
        return this.goFetch(`/Customers/customer/${id}`).then((_) => _.json());
    }

    public getCustomers(
        apiKey: string,
        s: string,
        // includeClaimed: boolean,
        _categories: string[]
    ): Promise<Customer[]> {
        let filters = {
            includeClaimed: true,
            categoies: _categories,
            searchString: s,
        };

        return this.goFetch("/Customers/all", undefined, "POST", filters, {
            "X-API-KEY": apiKey,
        }).then((_) => _.json());
    }

    public updateCustomer(c: Customer): Promise<void> {
        return this.goFetch(`/Customers/${c.claimId}`, undefined, "PUT", c);
    }

    public createCustomer(f: Customer): Promise<void> {
        return this.goFetch(`/Customers`, undefined, "POST", f);
    }

    public getCategories(): Promise<any> {
        return this.goFetch("/market/Categories").then((_) => _.json());
    }

    public getAdminApiKey(pin: string): Promise<any> {
        return this.goFetch("/market/pins", undefined, "POST", {
            PinCode: pin,
        }).then((_) => _.json());
    }

    public getCountries(): Promise<any> {
        return this.goFetch("/market/Countries").then((_) => _.json());
    }

    public getOrigins(): Promise<any> {
        return this.goFetch("/market/origins").then((_) => _.json());
    }

    public getProducts(): Promise<any> {
        return this.goFetch("/market/products").then((_) => _.json());
    }

    public getProductGroups(): Promise<any> {
        return this.goFetch("/market/productGroups").then((_) => _.json());
    }

    public getCompanyInfo(
        companyId: string,
        userId: string
    ): Promise<GetCompanyInfo> {
        let cleanCompanyId = companyId.replaceAll("/", "-");
        return this.goFetch(`/companies/company/${cleanCompanyId}/${userId}`)
            .then((response) => response.json())
            .catch((error) => {
                console.log(error);
            });
    }

    public postExtendCompany(formData: Partial<GetCompanyInfo>): Promise<void> {
        return this.goFetch(`/companies`, undefined, "POST", formData);
    }
}
