import React from "react";
import { runWithAdal } from "react-adal";
import ReactDOM from "react-dom";
import { authContext } from "./adalConfig";
import { App } from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

const DO_NOT_LOGIN = true;
runWithAdal(
    authContext,
    () => {
        ReactDOM.render(
            <App />,
            document.getElementById("root") as HTMLElement
        );

        serviceWorker.unregister();
    },
    DO_NOT_LOGIN
);

// Chat
const script = document.createElement("script");
script.id = "hs-script-loader";
script.src = "https://js.hs-scripts.com/8646321.js";
script.async = true;
script.defer = true;
document.body.appendChild(script);
