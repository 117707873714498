import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import React, { useContext, useEffect, useState } from "react";
import { event } from "react-ga";
import { BottomNav } from "../BottomNav/BottomNav";
import { useMatchMedia } from "../../hooks/useResize";
import { CompanyInformation } from "../../Steps/CompanyInformation/CompanyInformation";
import { Finished } from "../../Steps/Finished/Finished";
import { MembershipInformation } from "../../Steps/MembershipInformation/MembershipInformation";
import { TermsAndPayment } from "../../Steps/TermsAndPayment/TermsAndPayment";
import { UserInformation } from "../../Steps/UserInformation/UserInformation";
import AppStore from "../../stores/AppStore";
import { SignupForm } from "../../types/general";
import { ProductDataType, ProductGroup} from "../../types/membership";
import { AppUtils } from "../../utils/AppUtils";
import { prepareCustomerForForm, preparePost, preparePostAdmin} from "../../utils/utils";
import styles from "./signupContainer.module.scss";
import {initialFormState} from "./signupContainerHelper";
import { Redirect } from "react-router";
import { Loader } from "@progress/kendo-react-indicators";
import { Props } from "./Extend";
import { SelectMembership } from "../../Steps/SelectMembership/SelectMembership";
import { Stepper, StepperChangeEvent } from "@progress/kendo-react-layout";


export const Signup: React.FC<Props> = ({
    step,
    steps,
    handlers: {handlePrevious, handleStepChange, resetStepper, handleNext},
    setError,
}) => {
    const tablet = useMatchMedia(1024);
    const mobile = useMatchMedia(600);
    const store = useContext(AppStore);
    const [products, setProducts] = useState<ProductDataType[]>([]);
    const [productGroups, setProductGroups] = useState<ProductGroup[]>([]);
    const [formState, setFormState] = useState<SignupForm>();
    const id = AppUtils.getUrlParam("id", window.location.search);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);

    useEffect(()=>{
        getProducts();
        getProductGroups();
    },[])

    useEffect(() => {
        if (id) {
            store.api
                .getCustomer(id)
                .then((c) => {
                    if ("price" in c) {
                        setFormState(prepareCustomerForForm(c, productGroups));
                    } else {
                        setFormState({
                            ...initialFormState(productGroups),
                            claimId: id,
                        });
                    }
                })
                .catch((err) => {
                    setError(
                        "Something went wrong. Could not retrieve the customer."
                    );
                });
        }
        else{
            setFormState(initialFormState(productGroups));
        }
    }, [productGroups]);


    const getProductGroups = ()=>{
        store.api.getProductGroups()
        .then((groups: ProductGroup[]) => {
            setProductGroups(groups);
        })
        .catch((err) => {
            setError(
                "Could not retrieve the markets. Please contact us or try again later."
            );
        });
    }

    const getProducts = ()=>{
        store.api.getProducts()
        .then((bundles: ProductDataType[]) => {
            setProducts(bundles);
        })
        .catch((err) => {
            setError(
                "Could not retrieve the products. Please contact us or try again later."
            );
        });
    }

    useEffect(() => {
        event({ category: `Step: ${step.label}`, action: "View" });
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);


    const onSubmit = React.useCallback(
        (event) => {
            const { isValid} = event;
            const values = event.values as SignupForm;

            if (!isValid) {
                return;
            }
            handleNext();
            setFormState(values);
            if (step.isSubmitStep) {
                setIsSubmitting(true);
                if (id) {
                    const preparedValues = preparePostAdmin(values);
                    store.api
                        .updateCustomer(preparedValues)
                        .then(() => {
                            setIsSubmitting(false);
                        })
                        .catch(() => {
                            setIsSubmitting(false);
                            setHasSubmitError(true);
                        });
                } else {
                    const preparedValues = preparePost(values);
                    store.api
                        .createCustomer(preparedValues)
                        .then(() => {
                            setIsSubmitting(false);
                        })
                        .catch(() => {
                            setIsSubmitting(false);
                            setHasSubmitError(true);
                        });
                }
            }
        },
        [steps, step]
    );

    const getPage = (formRenderProps: FormRenderProps) => {
        switch (step.order){
            case 0 :
                return(
                    <UserInformation />
                );
            case 1 :
                return (<CompanyInformation />);
            case 2 :
                return(
                    <MembershipInformation
                        roles={formRenderProps.valueGetter("title")}
                        purpose={formRenderProps.valueGetter("buyerSellerViewer")}
                        companyRoleValue={formRenderProps.valueGetter("companyRole")}
                    />
                );
            case 3:
                return(
                    <SelectMembership
                        mobile={mobile}
                        formRenderProps={formRenderProps}
                        products={products}
                    />
                );
            case 4: 
                return(
                    <TermsAndPayment formRenderProps={formRenderProps} productGroups={productGroups} products={products}/>
                );
            case 5:
                return(
                    isSubmitting ? (<Loader />)
                    : hasSubmitError ? (<Redirect to={"/error"} />) 
                    : (<Finished />)
                );
           
        }
    }

    const onStepChange= (e: StepperChangeEvent, isValid: boolean): void => {
        const currentSteps = steps.map((currentStep, index) => ({
          ...currentStep,
          isValid: index === step.order ? isValid : currentStep.isValid,
        }));
        handleStepChange(e, currentSteps);
    };


    return (
        <Form
            initialValues={formState}
            key={JSON.stringify(formState)}
            onSubmitClick={onSubmit}
            render={(formRenderProps) => (
                <>
                    <div className={`${styles.contentMargin} ${step.isSelectMembershipPage ? styles.selectMembershipPage: ""}`}>
                        {!mobile && !step.isFirstStep &&(
                            <div className={styles.stepperMargin}>
                                <Stepper
                                    value={step.order}
                                    onChange={(e) => {onStepChange(e, formRenderProps.valid)}}
                                    items={steps}
                                    linear
                                    orientation="vertical"
                                    className={styles.stepper}
                                    disabled={step.isLastStep}
                                />
                            </div>
                        )}
                        <FormElement>
                            <div
                                className={`${styles.content} ${
                                    step.isFirstStep && !mobile && !tablet
                                        ? styles.introPage
                                        : ""
                                }`}
                            >
                                {getPage(formRenderProps)}
                            </div>
                            <BottomNav
                                handlePrevious={handlePrevious}
                                onSubmit={formRenderProps.onSubmit}
                                step={step}
                            />
                        </FormElement>
                    </div>
                </>
            )}
        />
    );
};