import { Slide } from "@progress/kendo-react-animation";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import styles from "./ErrorNotification.module.scss";
import React, { useEffect } from "react";

type IProps = {
    error: string;
    clear: () => void;
};

export const ErrorNotification: React.FC<IProps> = ({ error, clear }) => {
    // useEffect(() => {
    //     if (error) {
    //         setTimeout(() => clear(), 3000);
    //     }
    // }, [error]);

    return (
        <div className={styles.container}>
            <NotificationGroup style={{ display: "inline-block" }}>
                <Slide>
                    {error && (
                        <Notification
                            type={{ style: "error", icon: true }}
                            closable={true}
                            onClose={clear}
                        >
                            <span>{error}</span>
                        </Notification>
                    )}
                </Slide>
            </NotificationGroup>
        </div>
    );
};
