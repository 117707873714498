import { ProductGroup } from "types/membership";
import { IProductGroup } from "types/membershipInfo";
import {
    title,
    buyerSellerViewer,
    marketRole,
} from "../../Steps/MembershipInformation/pageData";
import { SignupForm } from "../../types/general";

export const initialFormState = (productGroups: ProductGroup[]): SignupForm => {
    const getProductGroups = () => {
        const pgs = productGroups.map((mr) => {
            let checkbox: IProductGroup = { 
                name: mr.id,
                label: mr.name,
                checked : mr.id == "eecs"
            };
            return checkbox;
        });

        return pgs;
    };

    return{
        contactName: "",
        email: "",
        phoneNumber: "",
        eikonUsername: "",
    
        companyName: "",
        vatNumber: "",
        orgNo: "",
        postalAddress: "",
        postalCode: "",
        postalCity: "",
        country: "",
    
        companyRole: marketRole,
        otherCompanyRole: "",
    
        buyerSellerViewer: buyerSellerViewer,
        title: title,
    
        paymentInterval: "year",
        productGroups: getProductGroups(),
        amount: undefined,
        extraUsers: 0,
        dealmanager: false,
    
        productId: undefined,
    
        tocAccepted: false,
        noAlerts: true,
        limits: []
    }
};

export const stepsConfig: Step[] = [
    { 
        label: "User Information", 
        isValid: undefined,
        isFirstStep: true,
        order: 0
    },
    {
        label: "Company information",
        isValid: undefined,
        order: 1
    },
    {
        label: "Membership information",
        isValid: undefined,
        isMembershipInfoPage: false,
        order: 2
    },
    {
        label: "Select your membership",
        isValid: undefined,
        isSelectMembershipPage: true,
        order: 3
    },
    {
        label: "Payment details",
        isValid: undefined,
        isSubmitStep: true,
        order: 4
    },
    {
        label: "Finished",
        isValid: undefined,
        isLastStep: true,
        order: 5
    }
];
export const extendingSteps: Step[] = [
    {
        label: "Select your membership",
        isValid: undefined,
        isSelectMembershipPage: true,
        order: 0
    },
    {
        label: "Payment details",
        isValid: undefined,
        isSubmitStep: true,
        order: 1
    },
    {
        label: "Finished",
        isValid: undefined,
        isLastStep: true,
        order: 2
    }
];

export interface Step{
    label: string;
    isValid: boolean | undefined;
    isSubmitStep?: boolean;
    order:number;
    isSelectMembershipPage? :boolean;
    isLastStep?: boolean;
    isFirstStep?:boolean;
    isMembershipInfoPage?:boolean;
}