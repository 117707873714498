import React, { FC } from "react";
import { Link } from "react-router-dom";

export const SubmitError: FC = ({ children }) => {
    return (
        <div style={{
            margin: "50px"
        }}>
            <h1>Something went wrong</h1>
            <p>We could not process your signup.</p>
            <p>
                Please verify your information by clicking the button below and
                go throught the steps.
            </p>
            {children}
            <p>
                If the problem persist, dont hesitate to contact us in the chat
                or by calling us at +47 413 45 660.
            </p>
        </div>
    );
};
