import { useContext, useEffect, useState } from "react";
import { Origin } from "types/membership";
import AppStore from "../stores/AppStore";

export const useOrigins = () => {
    const store = useContext(AppStore);
    const [origins, setOrigins] = useState<Origin[]>([]);

    useEffect(() => {
        store.api.getOrigins().then((origins) => {
            setOrigins(origins);
        });
    }, []);

    return origins;
};
