import React, { useEffect } from "react";
import styles from "./App.module.scss";
import { SignupContainer } from "./components/SignupContainer/SignupContainer";
import { Lookup } from "./components/Lookup/Lookup";
import "./styles/Global.scss";
import { initializeGA } from "./GA";
import { BrowserRouter, Route, Switch } from "react-router-dom";
export const App = () => {
    useEffect(() => {
        initializeGA();
    }, []);

    return (
        <div className={styles.app}>
            <BrowserRouter>
                <Switch>
                    <Route
                        exact={true}
                        path={"/lookup"}
                        render={(match) => {
                            return <Lookup />;
                        }}
                    />
                    <Route
                        path={"/extend"}
                        render={(match) => {
                            return (
                                <SignupContainer extending/>
                            );
                        }}
                    />
                    <Route
                        path={["/", "/signup"]}
                        render={(match) => {
                            return (
                                <SignupContainer />
                            );
                        }}
                    />
                </Switch>
            </BrowserRouter>
        </div>
    );
};
