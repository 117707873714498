import React from "react";
import { Input } from "@progress/kendo-react-inputs";
import styles from "./SearchCompanies.module.scss";
import { Button } from "@progress/kendo-react-buttons";

type IProps = {
    setSearchString: React.Dispatch<React.SetStateAction<string>>;
    search: () => void;
};

export const SearchCompanies: React.FC<IProps> = ({
    setSearchString,
    search,
}) => {
    return (
        <>
            <Input
                className={styles.searchBoxScale}
                placeholder="Search by company name or contact person"
                onChange={(ev) => {
                    setSearchString(ev.value);
                }}
            />

            <Button
                icon="search"
                onClick={search}
                className={styles.searchButton}
            />
        </>
    );
};
