import React from "react";
import { Feature, PaymentInterval, ProductDataType } from "../../../../types/membership";
import { MembershipPlanTable } from "./MembershipPlanTable/MembershipPlanTable";

type IProps = {
    products: ProductDataType[];
    paymentInterval: PaymentInterval;
};

export const MembershipPlan: React.FC<IProps> = ({ products, paymentInterval }) => (
    <MembershipPlanTable products={products} paymentInterval={paymentInterval}/>
);
