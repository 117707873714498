import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React, { useState } from "react";
import { FormCheckbox } from "../../components/FormInput";
import { termsValidator } from "../../validators";
import { SummaryCard } from "./components/SummaryCard/SummaryCard";
import { AddressCard } from "./components/AddressCard/AddressCard";
import styles from "./termsAndPayment.module.scss";
import Button from "../../components/Button/Button";
import pdfIcon from "../../icons/pdf.svg";
import { event } from "react-ga";
import { AppUtils } from "utils/AppUtils";
import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import { ProductDataType, ProductGroup } from "types/membership";

export interface ITermsAndPayment{
    formRenderProps: FormRenderProps,
    products: ProductDataType[];
    productGroups: ProductGroup[];
    extending?:boolean;
 }



export const TermsAndPayment: React.FC<ITermsAndPayment> = ({ formRenderProps, extending, productGroups, products}) => {
    const id = AppUtils.getUrlParam("id", window.location.search);
    const [hasReadTOC, setHasReadTOC] = useState<boolean>(false);
    const handleClickTerms = () => {
        setHasReadTOC(true);
        window.open("/resources/Terms and conditions - GPH.pdf");
        event({ category: `TOC PDF`, action: "Click" });
    };

    return (
        <div className={styles.container}>
            <div className={styles.cardWrapper}>
                <h2>Your membership plan</h2>
                <SummaryCard formRenderProps={formRenderProps} productGroups={productGroups} products={products}/>
                <div className={styles.grey}>
                    <p>Starts with 14-days free trial</p>
                </div>
            </div>
            <div className={styles.content}>
                {extending ? 
                    <ExtendingInfo />
                :   <PaymentInfo formRenderProps={formRenderProps}/>
                }
                <div className={styles.termsWrapper}>
                    <h2>Membership terms and conditions</h2>
                    <p>
                        Please read and accept the terms &amp; conditions to
                        continue
                    </p>
                    <div className={styles.sideBySide}>
                        <Button
                            buttonType="secondary2"
                            look="outline"
                            icon={pdfIcon}
                            className={styles.termsButton}
                            onClick={handleClickTerms}
                        >
                            Terms and conditions
                        </Button>
                        <Field
                            key={"tocAccepted"}
                            id={"tocAccepted"}
                            name={"tocAccepted"}
                            label={"I accept"}
                            component={FormCheckbox}
                            validator={termsValidator}
                            disabled={!hasReadTOC}
                            hint={`${
                                !hasReadTOC
                                    ? "You need to read through the terms before accepting"
                                    : ""
                            }`}
                        />
                    </div>
                    { id &&
                        <div>
                            <Field
                                key={"noAlerts"}
                                id={"noAlerts"}
                                name={"noAlerts"}
                                label={"Create without alerts"}
                                component={FormCheckbox}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};


const PaymentInfo : React.FC<{formRenderProps: FormRenderProps}> = ({formRenderProps})=>{
    return(
        <>
            <h2>Payment details</h2>
            <p>
                You are almost ready to start your 14-days free trial.
            </p>
            <p>
                If you choose to stay on{" "}<i>after</i>{" "}the free 14-days, an invoice will be sent to the postal address you have provided:
            </p>
            <PaymentAddressCard formRenderProps={formRenderProps} />
            <PaymentCard formRenderProps={formRenderProps} />
        </>
    )

}

const PaymentCard : React.FC<{formRenderProps: FormRenderProps}> = ({formRenderProps})=>{
    return(
        <Card style={{marginBottom:"24px"}}>
            <CardHeader>
                <h2 style={{paddingBottom: 0}} >
                    Contact details
                </h2>
            </CardHeader>
            <CardBody>
                <ul style={{listStyle:"none",padding: 0}}>
                    <li>
                        Name:{" "} {formRenderProps.valueGetter("contactName")}
                    </li>
                    <li>
                        Email:{" "} {formRenderProps.valueGetter("email")}
                    </li>
                    <li>
                        Phone Number:{" "}{formRenderProps.valueGetter("phoneNumber")}
                    </li>
                    <li>
                        Eikon Username:{" "}{formRenderProps.valueGetter("eikonUsername")}
                    </li>
                </ul>
            </CardBody>
        </Card>
    )
}

const PaymentAddressCard : React.FC<{formRenderProps: FormRenderProps}> = ({formRenderProps})=>{

    return(
        <AddressCard
            company={{
                adress: formRenderProps.valueGetter("postalAdress"),
                city: formRenderProps.valueGetter("postalCity"),
                postalCode: formRenderProps.valueGetter("postalCode"),
                country: formRenderProps.valueGetter("country"),
                companyName: formRenderProps.valueGetter("companyName")
            }}
        />
    )

}


const ExtendingInfo : React.FC = ()=>{
    return(
        <>
            <h2>Payment details</h2>
            <p> An Invoice will be sent to your company invoice address.</p>
        </>
    )

}