import {
    AutoComplete,
    ComboBox,
    ComboBoxFilterChangeEvent,
    DropDownList,
    MultiSelect,
} from "@progress/kendo-react-dropdowns";
import { Field, FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import {
    Checkbox,
    ColorPicker,
    Input,
    MaskedTextBox,
    NumericTextBox,
    RadioGroup,
    Slider,
    SliderLabel,
    Switch,
    TextArea,
} from "@progress/kendo-react-inputs";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
import * as React from "react";
import { filterBy } from "@progress/kendo-data-query";
import styles from "./FormInput.module.scss";
import { useOrigins } from "hooks/useOrigins";
import { Limit } from "types/extending";

export const FormInput = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        type,
        optional,
        ...others
    } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";

    return (
        <FieldWrapper className="fieldWrapper">
            <Label
                editorId={id}
                editorDisabled={disabled}
                optional={optional}
                className="block"
            >
                {label}
            </Label>
            <div className={"k-form-field-wrap"}>
                <Input
                    valid={valid}
                    type={type}
                    id={id}
                    disabled={disabled}
                    ariaDescribedBy={`${hintId} ${errorId}`}
                    {...others}
                />
                {showHint && <Hint id={hintId}>{hint}</Hint>}
                {showValidationMessage && (
                    <Error id={errorId}>{validationMessage}</Error>
                )}
            </div>
        </FieldWrapper>
    );
};

export const FormRadioGroup = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        id,
        label,
        valid,
        disabled,
        hint,
        visited,
        modified,
        ...others
    } = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";

    return (
        <FieldWrapper className="fieldWrapper">
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorDisabled={disabled}
                className="block"
            >
                {label}
            </Label>
            <RadioGroup
                // id={id}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ariaLabelledBy={labelId}
                valid={valid}
                disabled={disabled}
                ref={editorRef}
                className="radios"
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </FieldWrapper>
    );
};

export const FormNumericTextBox = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        ...others
    } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";

    return (
        <FieldWrapper className="fieldWrapper">
            <Label editorId={id} editorDisabled={disabled} className="block">
                {label}
            </Label>
            <NumericTextBox
                ariaDescribedBy={`${hintId} ${errorId}`}
                valid={valid}
                id={id}
                disabled={disabled}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </FieldWrapper>
    );
};

export const FormCheckbox = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        id,
        valid,
        disabled,
        hint,
        optional,
        label,
        visited,
        modified,
        ...others
    } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";

    return (
        <FieldWrapper className="checkboxWrapper">
            <Checkbox
                ariaDescribedBy={`${hintId} ${errorId}`}
                label={label}
                labelOptional={optional}
                valid={valid}
                id={id}
                disabled={disabled}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </FieldWrapper>
    );
};

export const FormSwitch = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        optional,
        id,
        valid,
        disabled,
        hint,
        ...others
    } = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";

    return (
        <FieldWrapper className="fieldWrapper">
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorDisabled={disabled}
                optional={optional}
                className="block"
            >
                {label}
            </Label>
            <Switch
                ref={editorRef}
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                valid={valid}
                id={id}
                disabled={disabled}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </FieldWrapper>
    );
};

export const FormMaskedTextBox = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        hint,
        optional,
        ...others
    } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";

    return (
        <FieldWrapper className="fieldWrapper">
            <Label editorId={id} optional={optional} className="block">
                {label}
            </Label>
            <div className={"k-form-field-wrap"}>
                <MaskedTextBox
                    ariaDescribedBy={`${hintId} ${errorId}`}
                    valid={valid}
                    id={id}
                    {...others}
                />
                {showHint && <Hint id={hintId}>{hint}</Hint>}
                {showValidationMessage && (
                    <Error id={errorId}>{validationMessage}</Error>
                )}
            </div>
        </FieldWrapper>
    );
};

export const FormTextArea = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        hint,
        disabled,
        optional,
        ...others
    } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";

    return (
        <FieldWrapper className="fieldWrapper">
            <Label editorId={id} optional={optional} className="block">
                {label}
            </Label>
            <TextArea
                valid={valid}
                id={id}
                disabled={disabled}
                ariaDescribedBy={`${hintId} ${errorId}`}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </FieldWrapper>
    );
};

export const FormColorPicker = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        wrapperStyle,
        ...others
    } = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";

    return (
        <FieldWrapper className="fieldWrapper" style={wrapperStyle}>
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
                className="block"
            >
                {label}
            </Label>
            <ColorPicker
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ref={editorRef}
                valid={valid}
                id={id}
                disabled={disabled}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </FieldWrapper>
    );
};

export const FormSlider = (fieldRenderProps: any) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        data,
        ...others
    } = fieldRenderProps;

    const editorRef = React.useRef(null);
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";

    return (
        <FieldWrapper className="fieldWrapper">
            <div>
                <Label
                    id={labelId}
                    editorRef={editorRef}
                    editorId={id}
                    editorDisabled={disabled}
                    className="block"
                >
                    {label}
                </Label>
            </div>
            <Slider
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ref={editorRef}
                valid={valid}
                id={id}
                buttons={false}
                disabled={disabled}
                {...others}
            >
                {data.map((num: number, i: number) => (
                    <SliderLabel key={i} position={num}>
                        {num.toString()}
                    </SliderLabel>
                ))}
            </Slider>
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </FieldWrapper>
    );
};

export const FormDropDownList = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        wrapperStyle,
        ...others
    } = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";

    return (
        <FieldWrapper className="fieldWrapper" style={wrapperStyle}>
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorDisabled={disabled}
                className="block"
            >
                {label}
            </Label>
            <DropDownList
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ref={editorRef}
                valid={valid}
                id={id}
                disabled={disabled}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </FieldWrapper>
    );
};

export const FormAutoComplete = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        wrapperStyle,
        ...others
    } = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";

    return (
        <FieldWrapper className="fieldWrapper" style={wrapperStyle}>
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorDisabled={disabled}
                className="block"
            >
                {label}
            </Label>
            <AutoComplete
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ref={editorRef}
                valid={valid}
                id={id}
                disabled={disabled}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </FieldWrapper>
    );
};

export const FormComboBox = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        wrapperStyle,
        data,
        ...others
    } = fieldRenderProps;
    const editorRef = React.useRef(null);
    const [comboBoxData, setComboBoxData] = React.useState([]);

    React.useEffect(() => {
        // initializing comboBoxData state with data caused issues.
        // therefor this seemingly useless useEffect
        setComboBoxData(data);
    }, [data]);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";

    const handleFilterChange = (event: ComboBoxFilterChangeEvent): void => {
        setComboBoxData(filterBy(data, event.filter));
    };

    return (
        <FieldWrapper className="fieldWrapper" style={wrapperStyle}>
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorDisabled={disabled}
                className="block"
            >
                {label}
            </Label>
            <ComboBox
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ref={editorRef}
                valid={valid}
                id={id}
                disabled={disabled}
                filterable={true}
                onFilterChange={handleFilterChange}
                data={comboBoxData}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </FieldWrapper>
    );
};

export const FormMultiSelect = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        wrapperStyle,
        ...others
    } = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";

    return (
        <FieldWrapper className="fieldWrapper" style={wrapperStyle}>
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorDisabled={disabled}
                className="block"
            >
                {label}
            </Label>
            <MultiSelect
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ref={editorRef}
                valid={valid}
                id={id}
                disabled={disabled}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </FieldWrapper>
    );
};

export const FormCheckboxGroup = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        id,
        label,
        valid,
        disabled,
        hint,
        visited,
        modified,
        data,
        name,
        onChange,
        value,
        ...others
    } = fieldRenderProps;

    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";

    const handleChange = (e: any) => {
        const updated = data.map((item: any) => {
            if (item.name === e.target.element.name) {
                return { ...item, checked: e.value };
            }
            return item;
        });

        onChange({
            target: name,
            value: updated,
        });
    };

    return (
        <FieldWrapper className="fieldWrapper">
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorDisabled={disabled}
                className="block"
            >
                {label}
            </Label>
            <div className={styles.flex}>
                {data.map((item: any) => (
                    <Checkbox
                        key={item.name}
                        className="checkboxWrapper"
                        label={item.label}
                        value={item.checked}
                        id={item.name}
                        name={item.name}
                        ariaDescribedBy={`${hintId} ${errorId}`}
                        valid={valid}
                        disabled={disabled}
                        onChange={handleChange}
                        {...others}
                    />
                ))}
            </div>
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </FieldWrapper>
    );
};


export const FormOrigin = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        id,
        label,
        valid,
        disabled,
        hint,
        visited,
        modified,
        data,
        name,
        onChange,
        value,
        ...others
    } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";

    const handleChange = (e: any) => {
        const updated : Limit[] =  [{ id: "origin", value: e.value.id }];
        onChange({
            target: name,
            value: updated,
        });
    };

    const origins= useOrigins();

    const getOriginValue = (id: string) =>{
        const eleValue = value?.find((v: Limit) => v.id == id);
        if(eleValue){
            const selected = origins.find(origin => origin.id == eleValue.value);
            return selected;
        }
    }

    const requiredOriginValidator = (id: string): string =>{
        const selectedValue =  getOriginValue(id);
        return selectedValue ? "" : "Error: This field is required.";;
    }

    return (
        <div style={{textAlign:"left"}}>
            {origins &&
                <Field
                    key={"origin"}
                    textField={"name"}
                    id={"origin"}
                    name={"origin"}
                    label={"Origin"}
                    value={getOriginValue("origin")}
                    component={FormComboBox}
                    onChange={handleChange}
                    data={origins}
                    validator={(value: string)=>  requiredOriginValidator("origin")}
                />
            }
        </div>
         
    );
};
