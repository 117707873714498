import React from "react";
import { useEffect } from "react";

// Returns true / false according to window width is smaller / larger
// than width specified
export const useMatchMedia = (width: number) => {
    const [breakpoint, setBreakpoint] = React.useState<boolean>();
    const point = `screen and (max-width: ${width}px)`;

    useEffect(() => {
        const matchMedia = window.matchMedia(point);
        setBreakpoint(matchMedia.matches);
        // Exception handling when in Safari <= 13 addEventListener is not supported
        // Newer browsers has replaced addListener with addEventListener.
        try {
            matchMedia.addEventListener("change", (ev) =>
                setBreakpoint(ev.matches)
            );
        } catch (err) {
            matchMedia.addListener((ev) => setBreakpoint(ev.matches));
        }

        return () => {
            // Exception handling when in Safari <= 13 addEventListener is not supported
            // Newer browsers has replaced addListener with addEventListener.
            try {
                matchMedia.removeEventListener("change", (ev) =>
                    setBreakpoint(ev.matches)
                );
            } catch (err) {
                matchMedia.removeListener((ev) => setBreakpoint(ev.matches));
            }
        };
    }, []);

    return breakpoint;
};
