import React, { FC } from "react";
import styles from "./TopMenu.module.scss";
import gphLogo from "../../img/gph-logo-signup.svg";

export const TopMenu: FC<{ extending?: boolean }> = ({ extending = false }) => {
    return (
        <div className={styles.container}>
            <img src={gphLogo} alt="Green Power Hub" />
            {extending && (
                <h2
                    style={{
                        color: "white",
                        fontStyle: "italic",
                        marginLeft: "36px",
                    }}
                >
                    Extending subscription - Please update or confirm fields
                </h2>
            )}
        </div>
    );
};
