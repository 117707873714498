export const emailBlacklist = [
    "126.com",
    "163.com",
    "aim.com",
    "alice.it",
    "aliceadsl.fr",
    "aliyun.com",
    "aol.com",
    "aol.it",
    "arcor.de",
    "arnet.com.ar",
    "att.net",
    "bellsouth.net",
    "bigpond.com",
    "bigpond.net.au",
    "blueyonder.co.uk",
    "bluewinch.ch",
    "bol.com.br",
    "bt.com",
    "btinternet.com",
    "centurytel.net",
    "charter.net",
    "chello.nl",
    "club-internet.fr",
    "comcast.net",
    "cox.net",
    "daum.net",
    "earthlink.net",
    "email.com",
    "email.it",
    "facebook.com",
    "fastmail.fm",
    "fibertel.com.ar",
    "foxmail.com",
    "free.fr",
    "freenet.de",
    "freeserve.co.uk",
    "frontiernet.net",
    "gmail.com",
    "games.com",
    "globo.com",
    "globomail.com",
    "gmx.com",
    "gmx.de",
    "gmx.fr",
    "gmx.net",
    "google.com",
    "googlemail.com",
    "hotmail.com",
    "hanmail.net",
    "hetnet.nl",
    "hotmail.be",
    "hotmail.co.uk",
    "hotmail.com.ar",
    "hotmail.com.br",
    "hotmail.com.mx",
    "hotmail.de",
    "hotmail.es",
    "hotmail.fr",
    "hotmail.it",
    "hush.com",
    "hushmail.com",
    "icloud.com",
    "ig.com.br",
    "iname.com",
    "inbox.com",
    "itelefonica.com.br",
    "juno.com",
    "live.com",
    "laposte.net",
    "lavabit.com",
    "libero.it",
    "list.ru",
    "live.be",
    "live.ca",
    "live.co.uk",
    "live.com.ar",
    "live.com.au",
    "live.com.mx",
    "live.de",
    "live.fr",
    "live.it",
    "live.nl",
    "love.com",
    "mac.com",
    "mail.com",
    "mail.ru",
    "me.com",
    "msn.com",
    "nate.com",
    "naver.com",
    "neuf.fr",
    "ntlworld.com",
    "outlook.com",
    "o2.co.uk",
    "oi.com.br",
    "online.de",
    "optionline.net",
    "optusnet.com.au",
    "orange.fr",
    "orange.net",
    "outlook.com.br",
    "planet.nl",
    "pobox.com",
    "poste.it",
    "prodigy.net.mx",
    "protonmail.com",
    "qq.com",
    "r7.com",
    "rambler.ru",
    "rediffmail.com",
    "rocketmail.com",
    "safe-mail.net",
    "sbcglobal.net",
    "sfr.fr",
    "shaw.ca",
    "sina.cn",
    "sina.com",
    "sky.com",
    "skynet.be",
    "speedy.com.ar",
    "sympatico.ca",
    "t-online.de",
    "talktalk.co.uk",
    "telenet.be",
    "teletu.it",
    "terra.com.br",
    "tin.it",
    "tiscali.co.uk",
    "tiscali.it",
    "tvcablenet.be",
    "uol.com.br",
    "voila.fr",
    "verizon.net",
    "virgilio.it",
    "virgin.net",
    "virginmedia.com",
    "voo.be",
    "wanadoo.co.uk",
    "wanadoo.fr",
    "web.de",
    "windstream.net",
    "wow.com",
    "yahoo.com",
    "ya.ru",
    "yahoo.ca",
    "yahoo.co.id",
    "yahoo.co.in",
    "yahoo.co.jp",
    "yahoo.co.kr",
    "yahoo.co.uk",
    "yahoo.com.ar",
    "yahoo.com.au",
    "yahoo.com.br",
    "yahoo.com.mx",
    "yahoo.com.ph",
    "yahoo.com.sg",
    "yahoo.de",
    "yahoo.es",
    "yahoo.fr",
    "yahoo.it",
    "yandex.com",
    "yandex.ru",
    "ygm.com",
    "ymail.com",
    "zipmail.com.br",
    "zoho.com",
    "zonnet.nl",
];
