import React from "react";
import { SvgIcon } from "@progress/kendo-react-common";
import { checkCircleIcon } from "@progress/kendo-svg-icons";
import styles from "./finished.module.scss";

declare global {
    interface Window {
        HubSpotConversations: any;
    }
}

window.HubSpotConversations = window.HubSpotConversations || {};

interface Props {}

export const Finished: React.FC<Props> = () => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <SvgIcon
                    icon={checkCircleIcon}
                    size="large"
                    themeColor={"primary"}
                />
                <h1>Thank you!</h1>
            </div>

            <p className={styles.highlight}>
                Thank you for signing up for the Green Power Hub.
            </p>
            <p>
                Your onboarding process starts now. First of all, our team will
                go through the information offered by you on your signup.
            </p>
            <p>
                Then our trade manager will get in touch with you to schedule a
                further introduction to the Green Power Hub. You will be offered
                a tour through the platform with its functions and a solid
                overview of the services offered to our members.
            </p>
            <p className={styles.highlight}>
                If your onboarding is urgent, please call +47 413 45 660.
            </p>
            <div className={styles.tySection}>
                <p>All the best.</p>
                <p>- The Green Power Hub Team</p>
            </div>
        </div>
    );
};
