import { Form, FormElement, FormRenderProps, FormSubmitClickEvent } from "@progress/kendo-react-form";
import { StepperChangeEvent } from "@progress/kendo-react-layout";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { event } from "react-ga";
import { BottomNav } from "../BottomNav/BottomNav";
import { useMatchMedia } from "../../hooks/useResize";
import { FinishedExtend } from "../../Steps/Finished/FinishedExtend";
import { SelectMembership } from "../../Steps/SelectMembership/SelectMembership";
import { TermsAndPayment } from "../../Steps/TermsAndPayment/TermsAndPayment";
import AppStore from "../../stores/AppStore";
import {ProductDataType, ProductGroup} from "../../types/membership";
import {Step} from "./signupContainerHelper";
import { Redirect } from "react-router";
import { useQuery } from "../../hooks/useQuery";
import { Loader } from "@progress/kendo-react-indicators";
import { ExtendForm } from "types/extending";
import { prepareExtendForm, prepareExtendFormForSubmit } from "utils/utils";
import styles from "./signupContainer.module.scss";

export interface Props{
    step: Step,
    steps: Step[],
    handlers: { 
        handlePrevious :()=>void, 
        handleStepChange: (e: StepperChangeEvent, currentSteps: Step[]) => void, 
        resetStepper :()=>void,
        handleNext : () => void;
    },
    setError:Dispatch<SetStateAction<string|undefined>>
}

export const Extend: React.FC<Props> = ({
    step,
    steps,
    handlers: { handlePrevious, handleStepChange, resetStepper, handleNext },
    setError,
}) => {
    const mobile = useMatchMedia(600);
    const store = useContext(AppStore);
    const [products, setProducts] = useState<ProductDataType[]>([]);
    const [productGroups, setProductGroups] = useState<ProductGroup[]>([]);
    const query = useQuery();
    const companyId = query.get("companyId");
    const userId = query.get("userId");
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
    const [company, setCompany] = useState<ExtendForm>();

    useEffect(() => {
        store.api.getProducts()
            .then((bundles: ProductDataType[]) => {
                setProducts(bundles);
            })
            .catch((err) => {
                setError(
                    "Could not retrieve the products. Please contact us or try again later."
                );
            });
        store.api.getProductGroups()
            .then((groups: ProductGroup[]) => {
                setProductGroups(groups);
            })
            .catch((err) => {
                setError(
                    "Could not retrieve the markets. Please contact us or try again later."
                );
            });
    }, []);

    useEffect(() => {
        if (companyId && userId)
            store.api.getCompanyInfo(companyId, userId).then((info) => {
                setCompany(prepareExtendForm(info,productGroups));
            });
    }, [productGroups]);

    useEffect(() => {
        event({ category: `Step: ${step.label}`, action: "View" });
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);


    const onSubmit = React.useCallback(
        (event) => {
            const { isValid} = event;
            const values = event.values as ExtendForm;

            if (!isValid) {
                return;
            }
            handleNext();
            setCompany(values);

            if (step.isSubmitStep) {
                setIsSubmitting(true);
                const c = prepareExtendFormForSubmit(values);

                store.api
                    .postExtendCompany(c)
                    .then(() => {
                        setIsSubmitting(false);
                    })
                    .catch(() => {
                        setIsSubmitting(false);
                        setHasSubmitError(true);
                    });
            }
        },
        [steps, step]
    )


    if (!companyId || !userId){
        return (
            <div style={{ padding: "20vw" }}>
                <h1>
                    You can only extend when coming from the Green Power Hub
                    application.
                </h1>
                <p>
                    Please contact us in the chat if you require any assistance.
                </p>
            </div>
        );    
    }


    if(!company || productGroups.length < 1 || products.length < 1){
        return(
            <Loader/>
        )
    }


    const getPage = (formRenderProps: FormRenderProps) => {
        switch (step.order){
            case 0 :
                return(
                    <SelectMembership
                        mobile={mobile}
                        formRenderProps={formRenderProps}
                        products={products}
                    />
                );
            case 1 :
                return (
                    <div style={{marginTop: "2rem", marginLeft: "2rem"}}>
                        <TermsAndPayment formRenderProps={formRenderProps} extending  productGroups={productGroups} products={products}/>
                    </div>
                );
            case 2 :
                return(
                    isSubmitting ? ( <Loader />) 
                    : hasSubmitError ? (
                        <Redirect
                            to={`/extend/error`}
                        />) 
                    : (<FinishedExtend />)
                );
        }
    }

    return (
        <div className={`${styles.contentMargin} ${step.isSelectMembershipPage ? styles.selectMembershipPage: ""}`}>
            <Form
                initialValues={company}
                key={JSON.stringify(company)}
                onSubmitClick={onSubmit}
                render={(formRenderProps) => (
                    <FormElement>
                        {getPage(formRenderProps)}
                        <BottomNav
                            handlePrevious={handlePrevious}
                            onSubmit={formRenderProps.onSubmit}
                            step={step}
                        />
                    </FormElement>
                )}
            />
        </div>
    );
};