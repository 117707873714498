import {
    DealManager,
    PaymentInterval,
} from "../types/membership";


export const paymentIntervals: PaymentInterval[] = [
    { name: "month", label: "Monthly", value: "month", discount: 0 },
    { name: "year", label: "Yearly", value: "year" , discount : 20 },
];

export const dealManager: DealManager = { 
    name: "dealmanager", 
    label: "Deal Manager"
};


