import styles from "./Button.module.scss";
import { Button as Btn, ButtonProps } from "@progress/kendo-react-buttons";
import { Spinner } from "../Spinner/Spinner";
import React from "react";

type ButtonTypes =
    | "primary"
    | "secondary"
    | "secondary2"
    | "info"
    | "success"
    | "warning"
    | "error";

interface IButton extends ButtonProps {
    buttonType?: ButtonTypes;
    loading?: boolean;
    className?: string;
    fullWidth?: boolean;
    icon?: string;
    iconPosition?: "left" | "right";
    [x: string]: any;
}

const Button: React.FC<IButton> = ({
    buttonType,
    loading,
    icon,
    className,
    fullWidth,
    iconPosition,
    ...props
}): JSX.Element => {
    const buttonTypeClass =
        buttonType === "primary"
            ? styles.primary
            : buttonType === "secondary"
            ? styles.secondary
            : buttonType === "secondary2"
            ? styles.secondary2
            : buttonType === "info"
            ? styles.info
            : buttonType === "success"
            ? styles.success
            : buttonType === "warning"
            ? styles.warning
            : buttonType === "error"
            ? styles.error
            : styles.primary;

    const cNames = [
        styles.container,
        buttonTypeClass,
        className,
        fullWidth ? styles.fullWidth : "",
    ].join(" ");

    return (
        <Btn type="button" className={cNames} {...props}>
            {iconPosition !== "right" && loading && (
                <Spinner className={[styles.icon, styles.left].join(" ")} />
            )}
            {iconPosition !== "right" && icon && !loading && (
                <img
                    src={icon}
                    className={[styles.icon, styles.left].join(" ")}
                />
            )}
            {props.children}
            {iconPosition === "right" && loading && (
                <Spinner className={[styles.icon, styles.right].join(" ")} />
            )}
            {iconPosition === "right" && !loading && (
                <img
                    src={icon}
                    className={[styles.icon, styles.right].join(" ")}
                />
            )}
        </Btn>
    );
};

export default Button;
