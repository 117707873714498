import { Field } from "@progress/kendo-react-form";
import React from "react";
import { CountryDropdown } from "../../components/CountryDropdown";
import { FormInput } from "../../components/FormInput";
import {
    companyNameValidator,
    requiredValidator,
    vatValidator,
} from "../../validators";
import styles from "./companyInformation.module.scss";
interface Props {}

export const CompanyInformation: React.FC<Props> = () => {
    return (
        <div>
            <div className={styles.container}>
                <div>
                    <h2>Company information</h2>
                    <Field
                        key={"companyName"}
                        id={"companyName"}
                        name={"companyName"}
                        label={"Company Name"}
                        component={FormInput}
                        validator={companyNameValidator}
                    />
                    <Field
                        key={"orgNo"}
                        id={"orgNo"}
                        name={"orgNo"}
                        label={"Org Number"}
                        component={FormInput}
                        validator={vatValidator}
                    />
                    <Field
                        key={"vatNumber"}
                        id={"vatNumber"}
                        name={"vatNumber"}
                        label={"Vat Number"}
                        component={FormInput}
                        validator={vatValidator}
                    />
                    <Field
                        key={"postalAddress"}
                        id={"postalAddress"}
                        name={"postalAddress"}
                        label={"Postal Address"}
                        component={FormInput}
                        validator={requiredValidator}
                    />
                    <Field
                        key={"postalCode"}
                        id={"postalCode"}
                        name={"postalCode"}
                        label={"Postal code"}
                        component={FormInput}
                        validator={requiredValidator}
                    />
                    <Field
                        key={"postalCity"}
                        id={"postalCity"}
                        name={"postalCity"}
                        label={"Postal City"}
                        component={FormInput}
                        validator={requiredValidator}
                    />
                    <CountryDropdown />
                </div>
            </div>
        </div>
    );
};
