let url = window.location.href.toLowerCase();
let isDev = true;

if (
    url.indexOf("gphsignup-prod-frontend.azurewebsites.net") >= 0 ||
    url.indexOf("signup.greenpowerhub.com") >= 0
) {
    isDev = false;
}

const tenantId = "73493f5e-5db2-425d-9042-0f400cee9c50";
export const clientId = isDev
    ? "f554e709-32a1-48b4-8534-6b1872348f3e"
    : "2f0437b8-228a-43b8-bd20-c3bb62812b2a";
export const apiId = isDev
    ? "27285846-f696-4fb3-a6de-8829a417480d"
    : "3278d85b-eacd-4641-b797-59dfdccc4d98";
export const apiUrl = isDev
    ? "https://gphsignup-dev-api.azurewebsites.net/api"
    : "https://gphsignup-prod-api.azurewebsites.net/api";

// export const apiUrl = "https://localhost:44370/api";

export const AppSettings = {
    tenantId,
    clientId,
    apiId,
    apiUrl,
    isDev,
};

export const localStorageKey = "form-state";
