import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./SwipeElements.module.scss";

const responsive = {
    standard: {
        breakpoint: { max: 5000, min: 0 },
        items: 1,
        slidesToSlide: 1,
    },
};

const SwipeElements: React.FC = (props) => (
    <div className={styles.container}>
        <Carousel
            swipeable={true}
            showDots={false}
            responsive={responsive}
            infinite={true}
            customTransition="all .5s"
            transitionDuration={500}
            arrows={false}
            centerMode={false}
            dotListClass={styles.carouselDots}
        >
            {props.children}
        </Carousel>
    </div>
);

export default SwipeElements;
