import { StepperChangeEvent } from "@progress/kendo-react-layout";
import { Step } from "components/SignupContainer/signupContainerHelper";
import { useEffect, useState } from "react";

export const useSteps = (
    initialSteps: Step[],
) => {
    const firstStep = initialSteps.find(_ => _.order == 0);
    const [step, setStep] = useState<Step>(firstStep!);
    const [steps, setSteps] = useState(initialSteps);

    const resetStepper = () => setStep(firstStep!);

    const handlePrevious = () => {
        const target = getStep(-1);
        if(target){
            setStep(target);
        }
    };

    const handleNext = () => {
        const target = getStep(1);
        if(target){
            setStep(target);
        }
    };

    const handleStepChange = (e: StepperChangeEvent, newSteps: Step[]): void => {
        const target = initialSteps.find(_ => _.order == e.value);
        if(target){
            if(target.isLastStep){
                return;
            }
            setSteps(newSteps);
            setStep(target);
        }
    };



    const getStep = (change : number)=>{
        const value = step.order + change;
        return initialSteps.find(_ => _.order == value);
    }

    return {
        step,
        steps,
        handlers: { handlePrevious, handleStepChange, resetStepper, handleNext },
    };
};
