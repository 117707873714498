import React, { useState } from "react";
import { IntroSidebar } from "../../components/IntroSidebar/IntroSidebar";
import { ErrorNotification } from "../../components/Notification/ErrorNotification/ErrorNotification";
import { TopMenu } from "../../components/TopMenu/TopMenu";
import { useMatchMedia } from "../../hooks/useResize";
import styles from "./signupContainer.module.scss";
import { stepsConfig, extendingSteps} from "./signupContainerHelper";
import { useSteps } from "../../hooks/useSteps";
import { Extend } from "./Extend";
import { Signup } from "./Signup";
import { Route, Switch, useHistory } from "react-router-dom";
import { SubmitError } from "components/SubmitError";
interface Props {
    extending?: boolean;
}

export const SignupContainer: React.FC<Props> = ({extending = false}) => {
    const tablet = useMatchMedia(1024);
    const [error, setError] = useState<string>();
    const {
        step,
        steps,
        handlers: { handlePrevious, handleStepChange, resetStepper, handleNext },
    } = useSteps(
        extending ? extendingSteps : stepsConfig
    );
    return (
        <div className={styles.container}>
            {(!step.isFirstStep || tablet) && <TopMenu extending={extending} />}
            <div className={styles.mainContainer}>
                {error &&
                    <ErrorNotification error={error} clear={() => setError(undefined)} />
                }
                {step.isFirstStep &&!tablet && !extending &&
                    <IntroSidebar />
                }
                <Switch>
                    <Route
                        path={["/error", "/extend/error"]}
                        render={(match) => {
                            return(
                                <ErrorPage/>
                            )
                        }}
                    />
                    <Route
                        path={["/"]}
                        render={(match) => {
                            return (
                                extending?
                                <Extend step={step} steps={steps} handlers={{ handlePrevious, handleStepChange, resetStepper, handleNext }} setError={setError}/>
                                : <Signup step={step} steps={steps} handlers={{ handlePrevious, handleStepChange, resetStepper, handleNext }} setError={setError}/>
                            );
                        }}
                    />
                </Switch>
            </div>
        </div>
    );
};

const ErrorPage: React.FC = () =>{
    let history = useHistory();
    return(
        <SubmitError>
            <div
                onClick={()=> {history.goBack()}}
                style={{
                    color: "inherit",
                    cursor: "pointer"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent:
                            "center",
                        background:
                            "orange",
                        padding: "24px",
                        color: "white",
                        maxWidth: "200px",
                        fontWeight:
                            "bolder",
                    }}
                >
                    Verify information
                </div>
            </div>
        </SubmitError>
    );
}