import React from "react";
import styles from "./Spinner.module.scss";

type IProps = {
    className: string;
};

export const Spinner: React.FC<IProps> = ({ className }) => {
    return <span className={[styles.spinner, className].join(" ")}></span>;
};
