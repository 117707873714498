import React from "react";
import { dealManager } from "../../../../layouts/layouts";
import { CheckboxGroup } from "../../../../components/CheckboxGroup/CheckboxGroup";

type IProps = {
    isChecked: boolean;
};

export const DealManager: React.FC<IProps> = ({ isChecked }) => {
    return (
        <CheckboxGroup
            data={[
                {
                    ...dealManager,
                    checked: isChecked,
                },
            ]}
            layout={"horizontal"}
            label="Add Deal Manager"
        />
    );
};
