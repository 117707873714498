import { Card, CardActions, CardBody } from "@progress/kendo-react-layout";
import React, { FC, useState } from "react";
import paymentIcon from "../../../icons/card.svg";
import mostValueIcon from "../../../icons/mostvalue.svg";
import usersIcon from "../../../icons/users.svg";
import dealManagerIcon from "../../../icons/deal-manager.svg";
import { PaymentInterval, ProductDataType} from "../../../types/membership";
import { getPrice } from "../../../utils/utils";
import Button from "../../Button/Button";
import styles from "./SubscriptionCard.module.scss";
import { dealManager } from "layouts/layouts";
import { Icon } from "@progress/kendo-react-common";
import { Field } from "@progress/kendo-react-form";
import { FormOrigin } from "components/FormInput";
import { Limit } from "types/extending";
import { useLocation } from "react-router-dom";
import { Popover } from "@progress/kendo-react-tooltip";
interface Props {
    product: ProductDataType;
    marketsSelected: string[],
    dealManagerSelected:boolean;
    extraUsers: number;
    onSubscriptionChange: (productId: string, e: React.MouseEvent<HTMLButtonElement, MouseEvent> ) => void;
    paymentInterval: PaymentInterval;
    selected?: boolean;
    limits?:Limit[];
}

export const SubscriptionCard: React.FC<Props> = ({
    product,
    marketsSelected,
    dealManagerSelected,
    onSubscriptionChange,
    paymentInterval,
    selected,
    extraUsers,
    limits,
}) => {
    const location = useLocation();
    const  includeSignupFee = !location.pathname.includes("extend");

    const mostValue = product.id =="premium";
    const cardColor= product.id == "viewer"? "white" : "dark";

    const dealManagerIncluded = product.includedFeatures.find(feature => feature.feature.id == dealManager.name);
    const dealManagerValue = product.extraFeatures.find(feature => feature.feature.id == dealManager.name);
    const dealManagerAdded = !dealManagerIncluded && dealManagerValue && dealManagerSelected;
    const dealManagerPrice = dealManagerAdded && dealManagerValue? dealManagerValue.price : 0
    const originalPrice = getPrice(product.price, marketsSelected.length, 0, dealManagerPrice, extraUsers, product.priceExtraUser?? 0, product.extraMarketPrice)
    const price = originalPrice * (100-paymentInterval.discount)/100;

    const cardClass = selected
        ? `${styles.card} ${styles[cardColor]} ${styles.existingCustomerSelected}`
        : `${styles.card} ${styles[cardColor]}`;



    return (
        <Card className={cardClass}>
            {mostValue && (
                <div className={styles.mostValue}>
                    <img src={mostValueIcon} />
                </div>
            )}
            <CardBody className={styles.cardBody}>
                <h2 className={styles.title}>
                    {product.name} {" "}
                    <PackageDescription product={product} />
                </h2>
                <p
                    className={styles.amount}
                >{`€ ${price}/mo`}</p>
                {paymentInterval.discount > 0 && (
                    <p className={styles.oldAmount}>
                        <span className={styles.oldAmountNumber}>
                            <span>
                            {`€ ${originalPrice}/mo`}
                            </span>
                        </span>{" "}
                        <span className={styles.discount}>
                            {`${paymentInterval.discount} %`}
                        </span>
                    </p>
                )}
                {includeSignupFee &&
                    <p className={styles.oldAmount}>Signup fee € {product.signupFee} </p>
                }
                <div className={styles.usersWrapper}>
                    <div className={styles.users}>
                        <img src={usersIcon} />
                        {product.users}
                        {extraUsers > 0 && (
                            `+ ${extraUsers}`
                        )}
                    </div>
                </div>
                <div className={styles.markets}>
                    <p>{marketsSelected.join(", ")}</p>
                </div>
                {dealManagerAdded && (
                    <div className={styles.usersWrapper}>
                        <div className={styles.users}>
                            <img src={dealManagerIcon} />
                            <div>{dealManager.label}</div>
                        </div>
                    </div>
                )}
                <div className={styles.paymentIntervalWrapper}>
                    <div className={styles.paymentInterval}>
                        <img src={paymentIcon} />
                        <div> {paymentInterval.label} </div>
                    </div>
                </div>
            </CardBody>
            <CardActions className={styles.cardActions}>
                <div className={styles.cardActionsWrapper}>
                    { selected && product.id == "domistic" && 
                        <Field
                            id={"limits"}
                            name={"limits"}
                            value={limits}
                            component={FormOrigin}
                        />
                    }

                    <div className={styles.selectWrapper}>
                        <Button
                            primary
                            disabled={selected}
                            onClick={(
                                e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                            ) =>
                                onSubscriptionChange(product.id, e)
                            }
                        >
                            {selected? "Selected": "Select"}
                        </Button>
                    </div>
                </div>
            </CardActions>
        </Card>
    )
};


const PackageDescription : React.FC<{product :ProductDataType}>= ({product})=>{
    const [calloutVisible, setCalloutVisible] = useState<boolean>(false);
    const anchor = React.useRef<any>(null);

    const toggleIsCalloutVisible=()=>{
        setCalloutVisible(!calloutVisible);
    }

    if(!product.description && !product.url){
        return null;
    } 
    if(!product.description){
        return (
            <a href={product.url} target="_blank" style = {{color: "inherit", textDecoration: "none", verticalAlign: "text-bottom"}}>
                <Icon style={{fontSize: "1.5rem"}} name={"information"} />
            </a>
        );
    }


    return (
        <>
            <Icon ref={anchor} style={{fontSize: "1.5rem", verticalAlign: "text-bottom"}} name={"information"} onClick={toggleIsCalloutVisible}/>
            <Popover
                show={calloutVisible}
                anchor={anchor.current && anchor.current.element}
                title={(
                    <h3>
                        {product.name}
                        <Icon name={"close-outline"} onClick={toggleIsCalloutVisible} style={{float: "right"}}/>
                    </h3>
                )}
                callout
                margin={{ vertical: 0, horizontal: 0 }}
                style={{
                    background: "#fff",
                    padding: "20px",
                    maxWidth: "300px"
                }}
            >
                <p> {product.description}</p>
                {product.url &&
                    <a href={product.url} target="_blank">
                        see more ...
                    </a>
                }
              
            </Popover>
        </>
    )
}