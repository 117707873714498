import {
    IBuyerSellerViewer,
    ITitle,
    IMarketRole,
} from "../../types/membershipInfo";

export const buyerSellerViewer: IBuyerSellerViewer[] = [
    { label: "Buy", name: "Buyer", checked: false },
    { label: "Sell", name: "Seller", checked: false },
    { label: "View the market", name: "Viewer", checked: false },
];

export const title: ITitle[] = [
    { label: "Trader", name: "Trader", checked: false },
    { label: "Backoffice", name: "Backoffice", checked: false },
    { label: "Risk manager", name: "RiskManager", checked: false },
    { label: "Manager", name: "Manager", checked: false },
];

export const marketRole: IMarketRole[] = [
    { label: "Producer", name: "Producer", checked: false },
    { label: "Consumer", name: "Consumer", checked: false },
    { label: "Broker", name: "Broker", checked: false },
    { label: "Consulting", name: "Consulting", checked: false },
    { label: "Analytics", name: "Analytics", checked: false },
    { label: "Other", name: "Other", checked: false },
];
