import ReactGA from "react-ga";
import {AppSettings} from "./appSettings";


const trackingIdProd = "UA-145831590-1";
const trackingIdDev = "UA-145831590-2";

const DEFAULT_CONFIG = {
    trackingId: AppSettings.isDev ? trackingIdDev : trackingIdProd,
    debug: AppSettings.isDev,
    gaOptions: {
        cookieDomain: "none",
    },
};

export const initializeGA = (): void => {
    ReactGA.initialize([DEFAULT_CONFIG]);
};
