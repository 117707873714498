import { Field } from "@progress/kendo-react-form";
import React from "react";
import { FormCheckboxGroup, FormInput } from "../../components/FormInput";
import { ICheckbox } from "../../types/general";
import {
    otherCompanyRoleValidator,
    requiredCheckboxValidator,
} from "../../validators";
import styles from "./MembershipInformation.module.scss";

interface Props {
    companyRoleValue: ICheckbox[];
    purpose: ICheckbox[];
    roles: ICheckbox[];
}
export const MembershipInformation: React.FC<Props> = ({
    companyRoleValue,
    purpose,
    roles,
}) => {
    const isOtherSelected = companyRoleValue.find(
        (item) => item.name === "Other"
    )?.checked;
    return (
        <div className={styles.container}>
            <h2>Membership information</h2>
            <div>
                <Field
                    key={"companyRole"}
                    id={"companyRole"}
                    name={"companyRole"}
                    label={"What is your companies market role?"}
                    layout={"horizontal"}
                    component={FormCheckboxGroup}
                    data={companyRoleValue}
                    validator={requiredCheckboxValidator}
                />
                {isOtherSelected ? (
                    <div className={styles.other}>
                        <Field
                            key={"otherCompanyRole"}
                            id={"otherCompanyRole"}
                            name={"otherCompanyRole"}
                            label={"Other"}
                            component={FormInput}
                            validator={otherCompanyRoleValidator}
                            hint="Specify your companies market role"
                        />
                    </div>
                ) : null}
                <Field
                    key={"buyerSellerViewer"}
                    id={"buyerSellerViewer"}
                    name={"buyerSellerViewer"}
                    label={"What would you like to do at green power hub?"}
                    layout={"horizontal"}
                    component={FormCheckboxGroup}
                    data={purpose}
                    validator={requiredCheckboxValidator}
                />
                <Field
                    key={"title"}
                    id={"title"}
                    name={"title"}
                    label={"What is your role?"}
                    layout={"horizontal"}
                    component={FormCheckboxGroup}
                    data={roles}
                    validator={requiredCheckboxValidator}
                />
            </div>
        </div>
    );
};
